import { SET_LINE_CHART_CONTAINER_SIZE } from './lineChartContainerSizeType'

const initialState = {
    lineChartContainerSize: {}
}

const setLineChartContainerSizeReducer = (state = initialState, action)=> {
    switch(action.type){
        case SET_LINE_CHART_CONTAINER_SIZE: return{
            ...state,
            lineChartContainerSize: action.payload
        }

        default: return state;
    }
}

export default setLineChartContainerSizeReducer;