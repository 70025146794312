import { SET_SELECTED_PAGE_FILTER } from './selectedPageFilterType'

const initialState = []

const setSelectedPageFilterReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_SELECTED_PAGE_FILTER: return{
            ...state,
            selectedPageFilter: action.payload
        }

        default: return state;
    }
}

export default setSelectedPageFilterReducer;