import { SET_MAP_RESIZE } from './mapResizeType'

const initialState = []

const setMapResizeReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_MAP_RESIZE: return{
            ...state,
            mapResizeData: action.payload
        }

        default: return state;
    }
}

export default setMapResizeReducer;