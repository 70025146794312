import { SET_DEFAULT_PAGE_LAYOUT } from './defaultPageLayOutType'

const initialState = []

const setDefaultPageLayOutReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_DEFAULT_PAGE_LAYOUT: return{
            ...state,
            defaultPageLayOut: action.payload
        }

        default: return state;
    }
}

export default setDefaultPageLayOutReducer;