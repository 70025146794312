import { SET_DONUT_CHART_CONTAINER_SIZE } from './donutChartContainerSizeType'

const initialState = {
    donutChartContainerSize: {}
}

const setDonutChartContainerSizeReducer = (state = initialState, action)=> {
    switch(action.type){
        case SET_DONUT_CHART_CONTAINER_SIZE: return{
            ...state,
            donutChartContainerSize: action.payload
        }

        default: return state;
    }
}

export default setDonutChartContainerSizeReducer;