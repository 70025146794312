import { SET_CHART_BODY_LABEL } from './chartBodyLabelType'

const initialState = []

const setChartBodyLabelReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_CHART_BODY_LABEL: return{
            ...state,
            chartBodyLabel: action.payload
        }

        default: return state;
    }
}

export default setChartBodyLabelReducer;