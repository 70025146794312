import { SET_USER_LOGIN_TOKEN } from './userLoginTokenType'

const initialState = ''

const setUserLoginTokenReducer = (state = initialState, action) =>{
    switch(action.type){
        
        case SET_USER_LOGIN_TOKEN: return{
            ...state,
            userLoginToken: action.payload
        }

        default: return state;
    }
}

export default setUserLoginTokenReducer;