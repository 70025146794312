import { SET_SELECTED_VISION_CLIENT } from './selectedVisionClientType'

const initialState = {}

const setSelectedVisionClientReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_SELECTED_VISION_CLIENT: return{
            ...state,
            selectedVisionClient: action.payload
        }

        default: return state;
    }
}

export default setSelectedVisionClientReducer;