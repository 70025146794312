export const GRANT_TYPE_PASSWORD = 'GRANT_TYPE_PASSWORD'

export const GRANT_TYPE_ACCESS_TOKEN = 'GRANT_TYPE_ACCESS_TOKEN'

export const GRANT_TYPE_REFRESH_TOKEN = 'GRANT_TYPE_REFRESH_TOKEN'

export const GRANT_TYPE_ID_TOKEN_EXCHANGE = 'GRANT_TYPE_ID_TOKEN_EXCHANGE'

export const GRANT_TYPE_ID_REFRESH_TOKEN = 'GRANT_TYPE_ID_REFRESH_TOKEN'

export const LOAD_WIDGETS = "LOAD_WIDGETS"

export const COUNT_CLICK = "COUNT_CLICK"

export const UNQUE_USER_IDENTIFIER_COOKIE = 'UUID'

//FKUserType = 1
export const VLIVE_LOGIN_NO = "VLIVE_LOGIN_NO"

//FKUserType 2 Or 3
export const VLIVE_LOGIN_YES = "VLIVE_LOGIN_YES"

export const INTERNAL_SERVER_ERROR = "Server error occurred" 