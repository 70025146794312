import { SET_SITE_CONSTANTS } from './siteConstantsType'

const initialState = {
    siteConstants: []
}

const setSiteConstantsReducer = (state = initialState, action)=> {
    switch(action.type){
        case SET_SITE_CONSTANTS: return{
            ...state,
            siteConstants: action.payload
        }

        default: return state;
    }
}

export default setSiteConstantsReducer;