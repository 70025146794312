import { SET_BAR_CHART_DRILL_DOWN_LABEL_TYPE } from './barChartDrillDownLabelType'

const initialState = []

const setBarChartDrillDownLabelReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_BAR_CHART_DRILL_DOWN_LABEL_TYPE: return{
            ...state,
            barChartDrillDownLabel: action.payload
        }

        default: return state;
    }
}

export default setBarChartDrillDownLabelReducer;