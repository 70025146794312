import Loadable from 'react-loadable';
import LoaderApp from './app/components/LoaderApp/LoaderApp'

export const LoadableOAuthToken = Loadable({
  loader: () => import('./app/components/OAuthTokenComponent/OAuthTokenComponent'),
  loading: LoaderApp,
  delay: 300
})

export const LoadableHome = Loadable({
    loader: () => import('./app/components/Home/Home'),
    loading: LoaderApp,
    delay: 300
  })

export const LoadableError = Loadable({
    loader: () => import('./app/components/Error/Error'),
    loading: LoaderApp,
    delay: 300
  })

  export const LoadableUnAuthorizedUser = Loadable({
    loader: () => import('./app/components/UnAuthorizedUser/UnAuthorizedUser'),
    loading: LoaderApp,
    delay: 300
  })

  export const LoadableMaintaincePage = Loadable({
    loader: () => import('./app/components/CommonPage/MaintaincePage'),
    loading: LoaderApp,
    delay: 300
  })

  export const LoadableOAuthError = Loadable({
    loader: () => import('./app/components/OAuthErrorComponent/OAuthErrorComponent'),
    loading: LoaderApp,
    delay: 300
  })


  









