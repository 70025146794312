import { store } from "./../redux/store";

import setSelectedPageFilterAction from './../redux/selectedPageFilter/selectedPageFilterAction'

import setDefaultPageLayOutAction from './../redux/defaultPageLayOut/defaultPageLayOutAction'

import setPageFiltersAction from './../redux/pageFilters/pageFiltersAction'

import setMenuOptionsAction from './../redux/menuOptions/menuOptionsAction'

import setVisionClientsAction from './../redux/visionClients/visionClientsAction'

import setChartLevelAction from './../redux/chartLevel/chartLevelAction'

import setBarChartDrillDownLabelAction from './../redux/barChartDrillDownLabel/barChartDrillDownLabelAction'

import setPieChartDrillDownLabelAction from './../redux/pieChartDrillDownLabel/pieChartDrillDownLabelAction'

import setDoughnutChartDrillDownLabelAction from './../redux/doughnutChartDrillDownLabel/doughnutChartDrillDownLabelAction'

import setFunnelChartDrillDownLabelAction from './../redux/funnelChartDrillDownLabel/funnelChartDrillDownLabelAction'

import setGaugeChartDrillDownLabelAction from './../redux/gaugeChartDrillDownLabel/gaugeChartDrillDownLabelAction'

import setLineChartDrillDownLabelAction from './../redux/lineChartDrillDownLabel/lineChartDrillDownLabelAction'

import setStackedBarChartDrillDownLabelAction from './../redux/stackedBarChartDrillDownLabel/stackedBarChartDrillDownLabelAction'

import setChartBodyLabelAction from './../redux/chartBodyLabel/chartBodyLabelAction'

import setActiveUserAction from './../redux/activeUser/activeUserAction'

import selectedMenuOptionAction from './../redux/selectedMenuOption/selectedMenuOptionAction'

import setEditedWidgetAction from './../redux/editedWidget/editedWidgetAction'

import setSiteConstantsAction from './../redux/siteConstants/siteConstantsAction'

import setStoreDashboardBrandColorsAction from './../redux/dashboardBrandColors/dashboardBrandColorsAction'

import setBarChartContainerSizeAction from './../redux/barChartContainerSize/barChartContainerSizeAction'

import setBarChartYearAction from './../redux/barChartYear/barChartYearAction'

import setDonutChartContainerSizeAction from './../redux/donutChartContainerSize/donutChartContainerSizeAction'

import setDonutChartYearAction from './../redux/donutChartYear/donutChartYearAction'

import setDummyPageLayOutAction from './../redux/dummyPageLayOut/dummmyPageLayOutAction'

import setLayOutAction from './../redux/layOut/layOutAction'

import setLineChartContainerSizeAction from './../redux/lineChartContainerSize/lineChartContainerSizeAction'

import setLineChartYearAction from './../redux/lineChartYear/lineChartYearAction'

import setPageFilterAvailableAction from './../redux/pageFilterAvailable/pageFilterAvailableAction'

import setPieChartContainerSizeAction from './../redux/pieChartContainerSize/pieChartContainerSizeAction'

import setPieChartYearAction from './../redux/pieChartYear/pieChartYearAction'

import setScreenSizeAction from './../redux/screenSize/screenSizeAction'

import selectedVisionClientAction from './../redux/selectedVisionClient/selectedVisionClientAction'

import setMapResizeAction from './../redux/mapResize/mapResizeAction' 

import setOAuthErrorDataAction from './../redux/oAuthError/oAuthErrorDataAction'

import setDataBaseProviderDataAction from './../redux/dataBaseProviderData/dataBaseProviderDataAction'

import setUserLoginTokenAction from './../redux/userLoginToken/userLoginTokenAction'

import setUserClaimsAction from './../redux/userClaims/userClaimsAction'

import { wait } from './commonService'

export const clearStore = async()=> {

            store.dispatch(setActiveUserAction({}))
            store.dispatch(setBarChartContainerSizeAction({}))
            store.dispatch(setBarChartDrillDownLabelAction([]))
            store.dispatch(setBarChartYearAction(''))
            store.dispatch(setChartBodyLabelAction([]))
            store.dispatch(setChartLevelAction([]))
            store.dispatch(setStoreDashboardBrandColorsAction({}))

            store.dispatch(setDefaultPageLayOutAction([]))
            store.dispatch(setDonutChartContainerSizeAction({}))
            store.dispatch(setDonutChartYearAction(''))
            store.dispatch(setDoughnutChartDrillDownLabelAction([]))
            store.dispatch(setDummyPageLayOutAction([]))
            store.dispatch(setEditedWidgetAction({}))
            store.dispatch(setFunnelChartDrillDownLabelAction([]))
            store.dispatch(setGaugeChartDrillDownLabelAction([]))
            

            store.dispatch(setLayOutAction(''))
            store.dispatch(setLineChartContainerSizeAction({}))

            store.dispatch(setLineChartDrillDownLabelAction([]))
            store.dispatch(setLineChartYearAction(''))
            store.dispatch(setMapResizeAction([]))
            store.dispatch(setMenuOptionsAction([]))
            store.dispatch(setPageFilterAvailableAction(''))
            store.dispatch(setPageFiltersAction({}))
            store.dispatch(setPieChartContainerSizeAction({}))
            store.dispatch(setPieChartDrillDownLabelAction([]))
            store.dispatch(setPieChartYearAction(''))
            store.dispatch(setScreenSizeAction({}))
            store.dispatch(selectedMenuOptionAction({}))
            store.dispatch(setSelectedPageFilterAction([]))
            store.dispatch(selectedVisionClientAction({}))
            store.dispatch(setSiteConstantsAction(''))
            store.dispatch(setStackedBarChartDrillDownLabelAction([]))
            store.dispatch(setVisionClientsAction([]))

            store.dispatch(setActiveUserAction({}))

            store.dispatch(setOAuthErrorDataAction({}))

            store.dispatch(setDataBaseProviderDataAction(''))

            store.dispatch(setUserLoginTokenAction(''))

            store.dispatch(setUserClaimsAction({}))

            return await wait(100);

}

