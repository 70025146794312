import { SET_FUNNEL_CHART_DRILL_DOWN_LABEL_TYPE } from './funnelChartDrillDownLabelType'

const initialState = []

const setFunnelChartDrillDownLabelReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_FUNNEL_CHART_DRILL_DOWN_LABEL_TYPE: return{
            ...state,
            funnelChartDrillDownLabel: action.payload
        }

        default: return state;
    }
}

export default setFunnelChartDrillDownLabelReducer;