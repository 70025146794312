import { SET_OAUTH_TOKEN_DATA } from './oAuthTokenDataType'

const initialState = {}

const setOAuthTokenDataReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_OAUTH_TOKEN_DATA: return{
            ...state,
            oAuthTokenData: action.payload
        }

        default: return state;
    }
}

export default setOAuthTokenDataReducer;