import { combineReducers, createStore, applyMiddleware } from '@reduxjs/toolkit';

import { persistStore, persistReducer } from 'redux-persist'

import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension';

import storage from 'redux-persist/lib/storage'

import setLayOutReducer from './layOut/layOutReducer'

import setBarChartContainerSizeReducer from './barChartContainerSize/barChartContainerSizeReducer'

import setDonutChartContainerSizeReducer from './donutChartContainerSize/donutChartContainerSizeReducer'

import setPieChartContainerSizeReducer from './pieChartContainerSize/pieChartContainerSizeReducer'

import setLineChartContainerSizeReducer from './lineChartContainerSize/lineChartContainerSizeReducer'

import setScreenSizeReducer from './screenSize/screenSizeReducer'

import setBarChartYearReducer from './barChartYear/barChartYearReducer'

import setDonutChartYearReducer from './donutChartYear/donutChartYearReducer'

import setPieYearReducer from './pieChartYear/pieChartYearReducer'

import setLineChartYearReducer from './lineChartYear/lineChartYearReducer'
import setPieChartYearReducer from './pieChartYear/pieChartYearReducer';

import setChartLevelReducer from './chartLevel/chartLevelReducer'

import setActiveUserReducer from './activeUser/activeUserReducer'

import setBarChartDrillDownLabelReducer from './barChartDrillDownLabel/barChartDrillDownLabelReducer'

import setSelectedVisionClientReducer from './selectedVisionClient/selectedVisionClientReducer'

import setSelectedMenuOptionReducer from './selectedMenuOption/selectedMenuOptionReducer'

import setSelectedPageFilterReducer from './selectedPageFilter/selectedPageFilterReducer'

import setEditedWidgetReducer from './editedWidget/editedWidgetReducer'

import setPieChartDrillDownLabelReducer from './pieChartDrillDownLabel/pieChartDrillDownLabelReducer'

import setDoughnutChartDrillDownLabelReducer from './doughnutChartDrillDownLabel/doughnutChartDrillDownLabelReducer'

import setLineChartDrillDownLabelReducer from './lineChartDrillDownLabel/lineChartDrillDownLabelReducer'

import setChartBodyLabelReducer from './chartBodyLabel/chartBodyLabelReducer'

import setStoreDashboardBrandColorsReducer from './dashboardBrandColors/dashboardBrandColorsReducer'

import setStackedBarChartDrillDownLabelReducer from './stackedBarChartDrillDownLabel/stackedBarChartDrillDownLabelReducer'

import setFunnelChartDrillDownLabelReducer from './funnelChartDrillDownLabel/funnelChartDrillDownLabelReducer'

import setGaugeChartDrillDownLabelReducer from './gaugeChartDrillDownLabel/gaugeChartDrillDownLabelReducer'

import setMapResizeReducer from './mapResize/mapResizeReducer'

import setSiteConstantsReducer from './siteConstants/siteConstantsReducer'

import setDefaultPageLayOutReducer from './defaultPageLayOut/defaultPageLayOutReducer'

import setDummyPageLayOutReducer from './dummyPageLayOut/dummyPageLayOutReducer'

import setPageFilterAvailableReducer from './pageFilterAvailable/pageFilterAvailableReducer'

import setPageFiltersReducer from './pageFilters/pageFiltersReducer'

import setMenuOptionsReducer from './menuOptions/menuOptionsReducer'

import setVisionClientsReducer from './visionClients/visionClientsReducer'

import setHubTokenDataReducer from './hubTokenData/hubTokenDataReducer'

import setOAuthTokenDataReducer from './oAuthToken/oAuthTokenDataReducer'

import setUserClaimsReducer from './userClaims/userClaimsReducer'

import setOAuthErrorDataReducer from './oAuthError/oAuthErrorDataReducer'

import setDataBaseProviderDataReducer from './dataBaseProviderData/dataBaseProviderDataReducer'

import setCSRFTokenReducer from './csrfToken/csrfTokenReducer'

import setUserLoginTokenReducer from './userLoginToken/userLoginTokenReducer'

const persistConfig = {
  key: 'root',
  storage:storage,
}

const appReducer = combineReducers(
  {
      layOut: setLayOutReducer,
      barChartContainerSize: setBarChartContainerSizeReducer,
      donutChartContainerSize: setDonutChartContainerSizeReducer,
      pieChartContainerSize: setPieChartContainerSizeReducer,
      lineChartContainerSize: setLineChartContainerSizeReducer,
      screenSize: setScreenSizeReducer,
      barChartYear: setBarChartYearReducer,
      donutChartYear: setDonutChartYearReducer,
      pieChartYear: setPieChartYearReducer,
      lineChartYear: setLineChartYearReducer,
      chartLevel: setChartLevelReducer,
      activeUser: setActiveUserReducer,
      barChartDrillDownLabel: setBarChartDrillDownLabelReducer,
      selectedVisionClient: setSelectedVisionClientReducer,
      selectedMenuOption: setSelectedMenuOptionReducer,
      selectedPageFilter: setSelectedPageFilterReducer,
      editedWidget: setEditedWidgetReducer,
      pieChartDrillDownLabel: setPieChartDrillDownLabelReducer,
      doughnutChartDrillDownLabel: setDoughnutChartDrillDownLabelReducer,
      lineChartDrillDownLabel: setLineChartDrillDownLabelReducer,
      chartBodyLabel: setChartBodyLabelReducer,
      dashboardBrandColors: setStoreDashboardBrandColorsReducer,
      stackedBarChartDrillDownLabel: setStackedBarChartDrillDownLabelReducer,
      funnelChartDrillDownLabel: setFunnelChartDrillDownLabelReducer,
      gaugeChartDrillDownLabel: setGaugeChartDrillDownLabelReducer,
      mapResizeData: setMapResizeReducer,
      siteConstants: setSiteConstantsReducer,
      defaultPageLayOut: setDefaultPageLayOutReducer,
      dummyPageLayOut: setDummyPageLayOutReducer,
      pageFilterAvailable: setPageFilterAvailableReducer,
      pageFilters: setPageFiltersReducer,
      menuOptions: setMenuOptionsReducer,
      visionClients: setVisionClientsReducer,
      hubTokenData: setHubTokenDataReducer,
      oAuthTokenData: setOAuthTokenDataReducer,
      userClaims: setUserClaimsReducer,
      oAuthErrorData: setOAuthErrorDataReducer,
      dataBaseProvider: setDataBaseProviderDataReducer,
      csrfToken: setCSRFTokenReducer,
      userLoginToken: setUserLoginTokenReducer
  }
)

const rootReducer = (state, action)=>{
  return appReducer(state, action);
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, composeWithDevTools(
  applyMiddleware(thunk)
  // other store enhancers if any
));

export const persistor = persistStore(store);


