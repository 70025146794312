
import { appBaseApiUrl } from "./../app/components/appconfig"

import { commonGet, commonPost } from './commonAjaxRequestService'

import $ from 'jquery'


export const oAuthAccessTokenGetAsync = async () => {

    let isError = false;

    let errorObj = null;

    let params = [
    ];

    let res = await commonGet('DashBoardToken/AccessTokenGetAsync', params)
        .catch(err => {

            isError = true;
            errorObj = err;

        })

    if (isError === true) {
        return new Promise(reject => reject(errorObj));
    }

    let data = res;

    return new Promise(resolve => resolve(data))

    // return new Promise((resolve, reject) => {

    //     $.ajax({
    //         type: 'GET',
    //         url: appBaseApiUrl + "DashBoardToken/AccessTokenGetAsync",
    //     })
    //         .then((data) => {
    //             resolve(data);
    //         })
    //         .fail((jqXHR, textStatus, errorThrown) => {
    //             let errorObject = {
    //                 jqXHR: jqXHR,
    //                 textStatus: textStatus,
    //                 errorThrown: errorThrown,
    //             };
    //             reject(errorObject);
    //         });


    // })

}

export const oAuthAccessToken = async () => {

    return new Promise((resolve, reject) => {

        $.ajax({
            type: 'POST',
            url: appBaseApiUrl + "DashBoardToken/AccessTokenAsync",
        })
            .then((data) => {
                resolve(data);
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                let errorObject = {
                    jqXHR: jqXHR,
                    textStatus: textStatus,
                    errorThrown: errorThrown,
                };
                reject(errorObject);
            });


    })

}

export const oAuthRefreshToken = async (accesstoken) => {

    return new Promise((resolve, reject) => {

        let object = new Object();
        object.accesstoken = accesstoken;

        $.ajax({
            type: 'POST',
            url: appBaseApiUrl + "DashBoardToken/RefreshTokenAsync",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(object)
        })
            .then((data) => {
                resolve(data);
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                let errorObject = {
                    jqXHR: jqXHR,
                    textStatus: textStatus,
                    errorThrown: errorThrown,
                };
                reject(errorObject);
            });


    })

}

export const tokenRefreshDummy = async () => {

    let isError = false;

    let errorObj = null;

    let params = [];

    let res = await commonGet('Widget/TokenRefreshDummy', params)
        .catch(err => {

            isError = true;
            errorObj = err;

        })

    if (isError === true) {
        return new Promise(reject => reject(errorObj));
    }

    let data = res

    return new Promise(resolve => resolve(data))


}

