import { SET_LAYOUT } from './layOutType'

const initialState = ''

const setLayOutReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_LAYOUT: return{
            ...state,
            layOut: action.payload
        }

        default: return state;
    }
}

export default setLayOutReducer;