export const wait = async (ms) => {

    if (!ms)
        ms = 100;

    await new Promise(resolve => setTimeout(resolve, ms))

}

export const pageFiltersDataFromStore = async() => {

    let res = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).pageFilters).pageFilters;

    let pageFilters = res && res.pageFilters;

    if (pageFilters
        && pageFilters.length > 0) {

        let displayNameLocal = res && res.displayName;

        let filterLocal = [];

        let displayTextItem = {
            id: -999,
            text: displayNameLocal
        }

        filterLocal.push(displayTextItem);

        pageFilters.map(f => {
            let item = {
                id: f.id,
                text: f.text
            }

            filterLocal.push(item);
        })

        return Promise.resolve(filterLocal);

    }
    else {

        let f = [];

        return Promise.resolve(f);
    }

}