import { getOverlappingDaysInIntervals } from "date-fns";

export const roundOffWith2Decimal = (value) => {

    if (value.match(/^\d*\,*\d*\.*\d+$/) != null) {
        let formattedValue = parseFloat(value.replace(/,/g, ''));
        let roundOffValue = parseFloat(formattedValue.toFixed(2));

        let addCommaValue = roundOffValue.toLocaleString('en-US')
        return addCommaValue;
    }
    else {
        return value;
    }
};

export const AddVisibityOfResizeHandle = (value) => {

    let resizableHandleCtrlsSouth = value.children(".react-resizable-handle.react-resizable-handle-s")
    let resizableHandleCtrlsWest = value.children(".react-resizable-handle.react-resizable-handle-w")
    let resizableHandleCtrlsEast = value.children(".react-resizable-handle.react-resizable-handle-e")
    let resizableHandleCtrlsNorth = value.children(".react-resizable-handle.react-resizable-handle-n")
    let resizableHandleCtrlsSouthWest = value.children(".react-resizable-handle.react-resizable-handle-sw")
    let resizableHandleCtrlsNorthWest = value.children(".react-resizable-handle.react-resizable-handle-nw")
    let resizableHandleCtrlsSouthEast = value.children(".react-resizable-handle.react-resizable-handle-se")
    let resizableHandleCtrlsNorthEast = value.children(".react-resizable-handle.react-resizable-handle-ne")

    if (resizableHandleCtrlsSouth) {

        if (resizableHandleCtrlsSouth.hasClass('active')) {

            resizableHandleCtrlsSouth.removeClass('active')
        }

        resizableHandleCtrlsSouth.addClass('active')
    }
    if (resizableHandleCtrlsWest) {

        if (resizableHandleCtrlsWest.hasClass('active')) {

            resizableHandleCtrlsWest.removeClass('active')
        }

        resizableHandleCtrlsWest.addClass('active')
    }
    if (resizableHandleCtrlsEast) {

        if (resizableHandleCtrlsEast.hasClass('active')) {

            resizableHandleCtrlsEast.removeClass('active')
        }

        resizableHandleCtrlsEast.addClass('active')
    }
    if (resizableHandleCtrlsNorth) {

        if (resizableHandleCtrlsNorth.hasClass('active')) {

            resizableHandleCtrlsNorth.removeClass('active')
        }

        resizableHandleCtrlsNorth.addClass('active')
    }
    if (resizableHandleCtrlsSouthWest) {

        if (resizableHandleCtrlsSouthWest.hasClass('active')) {

            resizableHandleCtrlsSouthWest.removeClass('active')
        }

        resizableHandleCtrlsSouthWest.addClass('active')
    }
    if (resizableHandleCtrlsNorthWest) {

        if (resizableHandleCtrlsNorthWest.hasClass('active')) {

            resizableHandleCtrlsNorthWest.removeClass('active')
        }

        resizableHandleCtrlsNorthWest.addClass('active')
    }
    if (resizableHandleCtrlsSouthEast) {

        if (resizableHandleCtrlsSouthEast.hasClass('active')) {

            resizableHandleCtrlsSouthEast.removeClass('active')
        }

        resizableHandleCtrlsSouthEast.addClass('active')
    }
    if (resizableHandleCtrlsNorthEast) {

        if (resizableHandleCtrlsNorthEast.hasClass('active')) {

            resizableHandleCtrlsNorthEast.removeClass('active')
        }

        resizableHandleCtrlsNorthEast.addClass('active')
    }

};


export const RemoveVisibityOfResizeHandle = (value) => {

    let resizableHandleCtrlsSouth = value.children(".react-resizable-handle.react-resizable-handle-s")
    let resizableHandleCtrlsWest = value.children(".react-resizable-handle.react-resizable-handle-w")
    let resizableHandleCtrlsEast = value.children(".react-resizable-handle.react-resizable-handle-e")
    let resizableHandleCtrlsNorth = value.children(".react-resizable-handle.react-resizable-handle-n")
    let resizableHandleCtrlsSouthWest = value.children(".react-resizable-handle.react-resizable-handle-sw")
    let resizableHandleCtrlsNorthWest = value.children(".react-resizable-handle.react-resizable-handle-nw")
    let resizableHandleCtrlsSouthEast = value.children(".react-resizable-handle.react-resizable-handle-se")
    let resizableHandleCtrlsNorthEast = value.children(".react-resizable-handle.react-resizable-handle-ne")


    if (resizableHandleCtrlsSouth) {

        resizableHandleCtrlsSouth.removeClass('active')

    }
    if (resizableHandleCtrlsWest) {

        resizableHandleCtrlsWest.removeClass('active')

    }
    if (resizableHandleCtrlsEast) {

        resizableHandleCtrlsEast.removeClass('active')

    }
    if (resizableHandleCtrlsNorth) {

        resizableHandleCtrlsNorth.removeClass('active')

    }
    if (resizableHandleCtrlsSouthWest) {

        resizableHandleCtrlsSouthWest.removeClass('active')

    }
    if (resizableHandleCtrlsNorthWest) {

        resizableHandleCtrlsNorthWest.removeClass('active')

    }
    if (resizableHandleCtrlsSouthEast) {

        resizableHandleCtrlsSouthEast.removeClass('active')

    }
    if (resizableHandleCtrlsNorthEast) {

        resizableHandleCtrlsNorthEast.removeClass('active')

    }


};


export const AddVisibityOfBtn = (value) => {
    if (value) {

        if (value.hasClass('active')) {
            value.removeClass('active')
        }

        value.addClass('active')

    }

};


export const RemoveVisibityOfBtn = (value) => {

    if (value) {
        value.removeClass('active')
    }

};

export const RootUrl = async() => {

   let origin = window.location.origin;

   return origin;

}

