
import { commonGet, commonPost } from './commonAjaxRequestService'

export const getChartData = async (filterData, drillDownData) => {

  let isError = false;

  let errorObj = null;

  let dataType = "json";
  let contentType = "application/json; charset=utf-8";

  let params = [{ orderYear: filterData },
  { customerCode: drillDownData }
  ];

  let res = await commonPost('BarChart/GetChartData', params, dataType, contentType)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res && res.data;

  return new Promise(resolve => resolve(data))

};

export const getChartDataAesthetics = async (level, fkVisionClient, fkMenuOption, pageFilterValue, mainFilterWidgetLevel, drillDownData1, drillDownData2, drillDownData3,
  drillDownData4, drillDownData5, pkWidget) => {

  let isError = false;

  let errorObj = null;

  let params = [{ level: level }, { fkVisionClient: fkVisionClient },
  { fkMenuOption: fkMenuOption }, { pageFilter: pageFilterValue },
  { widgetFilter: mainFilterWidgetLevel }, { drillDown1: drillDownData1 },
  { drillDown2: drillDownData2 }, { drillDown3: drillDownData3 },
  { drillDown4: drillDownData4 }, { drillDown5: drillDownData5 },
  { pkWidget: pkWidget }
  ];

  let res = await commonGet('Widget/GetWidgetData', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))


}

export const getChartDataAestheticsOnPremise = async (environment, user, groupName, sessionid, level, fkVisionClient, pkUser,
  fkMenuOption, pageFilterValue, mainFilterWidgetLevel, drillDownData1, drillDownData2, drillDownData3,
  drillDownData4, drillDownData5, pkWidget, encryptedusername, encrypteduserpassword, pkapplication, iteration, loadedwidgets, requestedwidgets, widgetsyettoload, numberofrequests) => {

  let isError = false;

  let errorObj = null;

  let params = [{ environment: environment }, { user: user },
  { groupName: groupName }, { sessionid: sessionid },
  { level: level }, { fkVisionClient: fkVisionClient },
  { pkUser: pkUser }, { fkMenuOption: fkMenuOption },
  { pageFilter: pageFilterValue }, { widgetFilter: mainFilterWidgetLevel },
  { drillDown1: drillDownData1 }, { drillDown2: drillDownData2 },
  { drillDown3: drillDownData3 }, { drillDown4: drillDownData4 },
  { drillDown5: drillDownData5 }, { pkWidget: pkWidget },
  { encryptedUserName: encodeURIComponent(encryptedusername) }, { encryptedPassword: encodeURIComponent(encrypteduserpassword) },
  { pkApplication: pkapplication }, { iteration: iteration },
  { loadedwidgets: loadedwidgets }, { requestedwidgets: requestedwidgets },
  { widgetsyettoload: widgetsyettoload }, { numberofrequests: numberofrequests }
  ];

  let res = await commonGet('Widget/GetWidgetDataOnPremise', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))


}

export const getChartDataAestheticsOnPremiseAsync = async (environment, sessionid, level, fkVisionClient,
  fkMenuOption, pageFilterValue, mainFilterWidgetLevel, drillDownData1, drillDownData2, drillDownData3,
  drillDownData4, drillDownData5, pkWidget, iteration, loadedwidgets, requestedwidgets, widgetsyettoload, numberofrequests) => {

  let isError = false;

  let errorObj = null;

  let params = [{ environment: environment },
  { sessionid: sessionid },
  { level: level }, { fkVisionClient: fkVisionClient },
  { fkMenuOption: fkMenuOption },
  { pageFilter: pageFilterValue }, { widgetFilter: mainFilterWidgetLevel },
  { drillDown1: drillDownData1 }, { drillDown2: drillDownData2 },
  { drillDown3: drillDownData3 }, { drillDown4: drillDownData4 },
  { drillDown5: drillDownData5 }, { pkWidget: pkWidget },
  { iteration: iteration },
  { loadedwidgets: loadedwidgets }, { requestedwidgets: requestedwidgets },
  { widgetsyettoload: widgetsyettoload }, { numberofrequests: numberofrequests }
  ];

  let res = await commonGet('Widget/GetWidgetDataOnPremiseAsync', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))

}

// export const clearOnPremiseDataTemp = async (sessionId) => {

//   let token = await oauthAccessToken();

//   return new Promise((resolve, reject) => {

//     $.ajax({
//       type: 'DELETE',
//       url: appBaseApiUrl + `Widget/ClearOnPremiseDataTempAsync?sessionid=${sessionId}`,
//       headers: { "Authorization": `Bearer ${token}` },
//     })
//       .then((data) => {
//         resolve(data);
//       })
//       .fail((jqXHR, textStatus, errorThrown) => {
//         let errorObject = {
//           jqXHR: jqXHR,
//           textStatus: textStatus,
//           errorThrown: errorThrown,
//         };
//         reject(errorObject);
//       });


//   })

// }


export const getChartFilters = async () => {

  let isError = false;

  let errorObj = null;

  let res = await commonGet('BarChart/GetDistinctYears', [])
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res && res.data;

  return new Promise(resolve => resolve(data))

};

export const getHubUrl = async () => {

  let isError = false;

  let errorObj = null;

  let res = await commonGet('Widget/GetHubUrl', [])
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res && res.data;

  return new Promise(resolve => resolve(data))

};

export const getExportData = async (pkWidget, fkVisionClient) => {

  let isError = false;

  let errorObj = null;

  let params = [{ pkWidget: pkWidget },
  { fkVisionClient: fkVisionClient }
  ];

  let res = await commonGet('Widget/GetExportData', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res;

  return new Promise(resolve => resolve(data))

};

//GetExportDataOnPremiseAsync
export const getExportDataOnPremise = async (environment, sessionid, fkVisionClient,
  pkWidget,
  iteration, loadedwidgets, requestedwidgets, widgetsyettoload, numberofrequests) => {

  let isError = false;

  let errorObj = null;

  let params = [{ environment: environment }, { fkVisionClient: fkVisionClient },
  { pkWidget: pkWidget }, { iteration: iteration },
  { loadedwidgets: loadedwidgets }, { requestedwidgets: requestedwidgets },
  { widgetsyettoload: widgetsyettoload }, { numberofrequests: numberofrequests }
  ];

  let res = await commonGet('Widget/GetExportDataOnPremise', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res && res.data

  return new Promise(resolve => resolve(data))

};

export const getExportDataOnPremiseAsync = async (environment, sessionid, fkVisionClient,
  pkWidget,
  iteration, loadedwidgets, requestedwidgets, widgetsyettoload, numberofrequests) => {

  let isError = false;

  let errorObj = null;

  let params = [{ environment: environment }, { sessionid: sessionid },
  { fkVisionClient: fkVisionClient }, { pkWidget: pkWidget },
  { iteration: iteration }, { loadedwidgets: loadedwidgets },
  { requestedwidgets: requestedwidgets }, { widgetsyettoload: widgetsyettoload },
  { numberofrequests: numberofrequests }
  ];

  let res = await commonGet('Widget/GetExportDataOnPremiseAsync', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res && res.data

  return new Promise(resolve => resolve(data))

};

export const getUserLogins = async (loggedIn) => {

  let isError = false;

  let errorObj = null;

  let params = [{ loggedIn: loggedIn }
  ];

  let res = await commonGet('Widget/GetUserLogins', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res && res.data;

  return new Promise(resolve => resolve(data))

};

export const verifyMultipleLogin = async () => {

  let isError = false;

  let errorObj = null;

  let res = await commonGet('Widget/MultipleLogin', [])
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res && res.multiplelogindetected;

  return new Promise(resolve => resolve(data))

};

export const getUserInterval = async () => {

  let isError = false;

  let errorObj = null;

  let params = [{ name: 'MultipleUserLoginPollInInterval' }
  ];

  let res = await commonGet('Widget/GetConstants', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res && res.data

  return new Promise(resolve => resolve(data))

};

export const insertUserLogin = async (fkUser, pkApplication, userName, userFullName, fkUserType, protectedTicket, logOff,
  user, visionClients, userVisionClients) => {

  let isError = false;

  let errorObj = null;

  let dataType = "json";
  let contentType = "application/json; charset=utf-8";

  let params = [{ FKUser: fkUser }, { PKApplication: pkApplication },
  { UserName: userName }, { UserFullName: userFullName },
  { FKUserType: fkUserType }, { ProtectedTicket: protectedTicket },
  { LogOff: logOff }, { User: user },
  { VisionClients: visionClients }, { UserVisionClients: userVisionClients }
  ];

  let res = await commonPost('Widget/InsertUserLogin', params, dataType, contentType)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res;

  return new Promise(resolve => resolve(data))

};

export const getWidgets = async (pkMenuOption) => {

  let isError = false;

  let errorObj = null;

  let params = [{ pkMenuOption: pkMenuOption }];

  let res = await commonGet('Widget/GetWidgets', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))
}

export const getDefaultPageLayOut = async (fkMenuOption) => {

  let isError = false;

  let errorObj = null;

  let params = [{ fkMenuOption: fkMenuOption }];

  let res = await commonGet('Widget/DefaultPageLayOut', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))

}

export const configuredWidgetDrillDown = async (level, pkWidget) => {

  let isError = false;

  let errorObj = null;

  let params = [{ level: level }, { pkWidget: pkWidget }];

  let res = await commonGet('Widget/ConfiguredWidgetDrillDown', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))

}

export const getConstantsData = async () => {

  let isError = false;

  let errorObj = null;

  let params = [];

  let res = await commonGet('Widget/GetConstants', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))

};

export const getDetailData = async (pkWidget, fkVisionClient) => {

  let isError = false;

  let errorObj = null;

  let params = [{ pkWidget: pkWidget }, { fkVisionClient: fkVisionClient }];

  let res = await commonGet('Widget/GetDetailData', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))

}

export const getDetailLabels = async (pkWidget, fkVisionClient) => {

  let isError = false;

  let errorObj = null;

  let params = [{ pkWidget: pkWidget }, { fkVisionClient: fkVisionClient }
  ];

  let res = await commonGet('Widget/GetDetailLabelsAsync', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))

}


export const getDetailDataOnPremiseAsync = async (environment, sessionid, fkVisionClient,
  pkWidget,
  iteration, loadedwidgets, requestedwidgets, widgetsyettoload, numberofrequests) => {

  let isError = false;

  let errorObj = null;

  let params = [{ environment: environment }, { sessionid: sessionid },
  { fkVisionClient: fkVisionClient }, { pkWidget: pkWidget },
  { iteration: iteration }, { loadedwidgets: loadedwidgets },
  { requestedwidgets: requestedwidgets }, { widgetsyettoload: widgetsyettoload },
  { numberofrequests: numberofrequests }
  ];

  let res = await commonGet('Widget/GetDetailDataOnPremiseAsync', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))

}

export const getUserVisionClients = async () => {

  let isError = false;

  let errorObj = null;

  let dataType = "json";
  let contentType = "application/json; charset=utf-8";

  let params = [
  ];

  let res = await commonPost('Widget/GetUserVisionClients', params,
    dataType, contentType)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return Promise.reject(errorObj)
  }

  let data = res;

  return Promise.resolve(data);

}

// export const dashboardSession = async () => {

//   let token = await oauthAccessToken();

//   return new Promise((resolve, reject) => {

//     $.ajax({
//       type: 'GET',
//       url: appBaseApiUrl + "VisionDashBoard/DashboardSession",
//       headers: { "Authorization": `Bearer ${token}` }

//     })
//       .then((data) => {
//         resolve(data);
//       })
//       .fail((jqXHR, textStatus, errorThrown) => {
//         let errorObject = {
//           jqXHR: jqXHR,
//           textStatus: textStatus,
//           errorThrown: errorThrown,
//         };
//         reject(errorObject);
//       });

//   })


// }

export const getfkDataBaseProvider = async () => {

  let isError = false;

  let errorObj = null;

  let params = [];

  let res = await commonGet('Widget/GetFKDataBaseProvider', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))

}

// export const removeUserFromWebClientsGroupAsync = async (pkuser, encryptedusername, encryptedpassword, pkapplication,
//   groupname, sessionid) => {

//   let token = await oauthAccessToken();

//   const model = new Object();
//   model.pkuser = pkuser;
//   model.encryptedusername = encryptedusername;
//   model.encryptedpassword = encryptedpassword;
//   model.pkapplication = pkapplication;
//   model.groupname = groupname;
//   model.sessionid = sessionid;

//   return new Promise((resolve, reject) => {

//     $.ajax({
//       url: appBaseApiUrl + "Widget/RemoveUserFromWebClientsGroupAsync",
//       headers: { "Authorization": `Bearer ${token}` },
//       type: "POST",
//       dataType: "json",
//       contentType: "application/json; charset=utf-8",
//       data: JSON.stringify(model),

//     })
//       .then((data, textStatus, jqXHR) => {
//         resolve(data);

//       })
//       .fail((jqXHR, textStatus, errorThrown) => {

//         let errorObject = {
//           jqXHR: jqXHR,
//           textStatus: textStatus,
//           errorThrown: errorThrown,
//         };
//         reject(errorObject);

//       });

//   })

// };

export const getPageFiltersAssociatedDataAsync = async (fkMenuOption) => {

  let isError = false;

  let errorObj = null;

  let params = [{ fkMenuOption: fkMenuOption }
  ];

  let res = await commonGet('Widget/GetPageFiltersAssociatedDataAsync', params)
    .catch(err => {

      isError = true;
      errorObj = err;

    })

  if (isError === true) {
    return new Promise(reject => reject(errorObj));
  }

  let data = res

  return new Promise(resolve => resolve(data))


}

// export const getVisionAppAuthorizationToken = async (uniqueuserid) => {

//   let token = await oauthAccessToken();

//   return new Promise((resolve, reject) => {

//     $.ajax({
//       type: 'GET',
//       url: appBaseApiUrl + "VisionDashBoard/VisionAppToken",
//       headers: { "Authorization": `Bearer ${token}` },
//       data: {
//         uniqueuserid: uniqueuserid
//       }

//     })
//       .then((data) => {
//         resolve(data);
//       })
//       .fail((jqXHR, textStatus, errorThrown) => {
//         let errorObject = {
//           jqXHR: jqXHR,
//           textStatus: textStatus,
//           errorThrown: errorThrown,
//         };
//         reject(errorObject);
//       });


//   })
// }

















