import { SET_DOUGHNUT_CHART_DRILL_DOWN_LABEL_TYPE } from './doughnutChartDrillDownLabelType'

const initialState = []

const setDoughnutDrillDownLabelReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_DOUGHNUT_CHART_DRILL_DOWN_LABEL_TYPE: return{
            ...state,
            doughnutChartDrillDownLabel: action.payload
        }

        default: return state;
    }
}

export default setDoughnutDrillDownLabelReducer;