import { SET_DATA_BASE_PROVIDER_DATA } from './dataBaseProviderDataType'

const initialState = ''

const setDataBaseProviderDataReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_DATA_BASE_PROVIDER_DATA: return{
            ...state,
            dataBaseProvider: action.payload
        }

        default: return state;
    }
}

export default setDataBaseProviderDataReducer;