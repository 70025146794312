import { SET_SCREEN_SIZE } from './screenSizeType'

const initialState = {
    screenSize: {}
}

const setScreenSizeReducer = (state = initialState, action)=> {
    switch(action.type){
        case SET_SCREEN_SIZE: return{
            ...state,
            screenSize: action.payload
        }

        default: return state;
    }
}

export default setScreenSizeReducer;