import { SET_USER_CLAIMS } from './userClaimsType'

const initialState = {}

const setUserClaimsReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_USER_CLAIMS: return{
            ...state,
            userClaims: action.payload
        }

        default: return state;
    }
}

export default setUserClaimsReducer;