import { SET_LINE_CHART_YEAR } from './lineChartYearType'

const initialState = {
    lineChartYear: ''
}

const setLineChartYearReducer = (state = initialState, action)=> {
    switch(action.type){
        case SET_LINE_CHART_YEAR: return{
            ...state,
            lineChartYear: action.payload
        }

        default: return state;
    }
}

export default setLineChartYearReducer;