import {SET_Dashboard_BRAND_COLORS} from './dashboardBrandColorsType'

const initialState = {}

const setStoreDashboardBrandColorsReducer = (state = initialState, action)=> {

    switch(action.type){
        case SET_Dashboard_BRAND_COLORS: return{
            ...state,
            dashboardBrandColors: action.payload
        }

        default: return state
    }
}

export default setStoreDashboardBrandColorsReducer;