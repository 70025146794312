import { appBaseApiUrl } from "./../app/components/appconfig";

import { clearStore } from './clearStoreService'

import $ from 'jquery'

import setHubTokenDataAction from './../redux/hubTokenData/hubTokenDataAction'

import setUserClaimsAction from './../redux/userClaims/userClaimsAction'

import setOAuthTokenDataAction from './../redux/oAuthToken/oAuthTokenDataAction'

import { store } from "./../redux/store";


export const userLogOut = async (searchParams) => {

    let url = appBaseApiUrl + "VisionDashBoard/LogOut";

    await clearStore();

    await new Promise(resolve => setTimeout(resolve, 400))

    store.dispatch(setHubTokenDataAction({}))

    store.dispatch(setOAuthTokenDataAction({}))

    store.dispatch(setUserClaimsAction({}))

    await new Promise(resolve => setTimeout(resolve, 300))

    localStorage.removeItem('persist:root')

    await new Promise(resolve => setTimeout(resolve, 300))

    let userName = searchParams.get('u');

    if (userName
        && userName?.length > 0) {
        userName = encodeURIComponent(userName)
    }

    let password = searchParams.get('p')

    if (password
        && password?.length > 0) {
        password = encodeURIComponent(password)
    }

    if (!userName
        || userName?.length <= 0) {
        userName = ''
    }

    if (!password
        || password?.length <= 0) {
        password = ''
    }

    let menuOption = searchParams.get('menuoption');
    let pageFilter = searchParams.get('pagefilter');

    if (!menuOption
        || menuOption?.length <= 0) {
        menuOption = ''
    }

    if (!pageFilter
        || pageFilter?.length <= 0) {
        pageFilter = ''
    }

    let UNName = "UserName";

    let PasswordName = "Password"

    let menuOptionName = "MenuOption";
    let pageFilterName = "PageFilter";

    var form = $('<form action="' + url + '" method="get" enctype="application/x-www-form-urlencoded">' +

        '<input type="hidden" name="' + UNName + '" value="' + userName + '" ></input>' +

        '<input type="hidden" name="' + PasswordName + '" value="' + password + '" ></input>' +

        '<input type="hidden" name="' + menuOptionName + '" value="' + menuOption + '" ></input>' +
        '<input type="hidden" name="' + pageFilterName + '" value="' + pageFilter + '" ></input>' +

        + '</form>');
    $('body').append(form);
    $(form).submit();

}

