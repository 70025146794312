import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { getConstantsData } from './../../../services/getChartData'
import setStoreDashboardBrandColorsAction from './../../../redux/dashboardBrandColors/dashboardBrandColorsAction'

import setSiteConstantsAction from './../../../redux/siteConstants/siteConstantsAction'

function ThemeSelector(props) {

    useEffect(() => {

        const getConstantsFn = async () => {

            let data = await getConstantsData();

            console.log('data = ', data)

            if (data && data.table0) {

                props.setStoreSiteConstants(data.table0);

                let colourBackground = data.table0.find(f => { return f.name.toUpperCase() === 'ColourBackground'.toUpperCase() })
                let colourBackGroundValue = colourBackground && colourBackground.value;

                let colourCardLine = data.table0.find(f => { return f.name.toUpperCase() === 'ColourCardLine'.toUpperCase() })
                let colourCardLineValue = colourCardLine && colourCardLine.value;

                let colourCardTextTitle = data.table0.find(f => { return f.name.toUpperCase() === 'ColourCardTextTitle'.toUpperCase() })
                let colourCardTextTitleValue = colourCardTextTitle && colourCardTextTitle.value;

                let colourChartLine = data.table0.find(f => { return f.name.toUpperCase() === 'ColourChartLine'.toUpperCase() })
                let colourChartLineValue = colourChartLine && colourChartLine.value;

                let colourChartText = data.table0.find(f => { return f.name.toUpperCase() === 'ColourChartText'.toUpperCase() })
                let colourChartTextValue = colourChartText && colourChartText.value;

                let colourKey = data.table0.find(f => { return f.name.toUpperCase() === 'ColourKey'.toUpperCase() })
                let colourKeyValue = colourKey && colourKey.value;

                let colourStringChartSeries = data.table0.find(f => { return f.name.toUpperCase() === 'ColourStringChartSeries'.toUpperCase() })
                let colourStringChartSeriesValue = colourStringChartSeries && colourStringChartSeries.value;

                let barChartContainerWidth = data.table0.find(f => { return f.name.toUpperCase() === 'BarChartContainerWidth'.toUpperCase() })
                let barChartContainerWidthValue = barChartContainerWidth && barChartContainerWidth.value;

                let barChartContainerHeight = data.table0.find(f => { return f.name.toUpperCase() === 'BarChartContainerHeight'.toUpperCase() })
                let barChartContainerHeightValue = barChartContainerHeight && barChartContainerHeight.value;

                let doughnutChartContainerWidth = data.table0.find(f => { return f.name.toUpperCase() === 'DoughnutChartContainerWidth'.toUpperCase() })
                let doughnutChartContainerWidthValue = doughnutChartContainerWidth && doughnutChartContainerWidth.value;

                let doughnutChartContainerHeight = data.table0.find(f => { return f.name.toUpperCase() === 'DoughnutChartContainerHeight'.toUpperCase() })
                let doughnutChartContainerHeightValue = doughnutChartContainerHeight && doughnutChartContainerHeight.value;

                let funnelChartContainerWidth = data.table0.find(f => { return f.name.toUpperCase() === 'FunnelChartContainerWidth'.toUpperCase() })
                let funnelChartContainerWidthValue = funnelChartContainerWidth && funnelChartContainerWidth.value;

                let funnelChartContainerHeight = data.table0.find(f => { return f.name.toUpperCase() === 'FunnelChartContainerHeight'.toUpperCase() })
                let funnelChartContainerHeightValue = funnelChartContainerHeight && funnelChartContainerHeight.value;

                let gaugeChartContainerWidth = data.table0.find(f => { return f.name.toUpperCase() === 'GaugeChartContainerWidth'.toUpperCase() })
                let gaugeChartContainerWidthValue = gaugeChartContainerWidth && gaugeChartContainerWidth.value;

                let gaugeChartContainerHeight = data.table0.find(f => { return f.name.toUpperCase() === 'GaugeChartContainerHeight'.toUpperCase() })
                let gaugeChartContainerHeightValue = gaugeChartContainerHeight && gaugeChartContainerHeight.value;

                let lineChartContainerWidth = data.table0.find(f => { return f.name.toUpperCase() === 'LineChartContainerWidth'.toUpperCase() })
                let lineChartContainerWidthValue = lineChartContainerWidth && lineChartContainerWidth.value;

                let lineChartContainerHeight = data.table0.find(f => { return f.name.toUpperCase() === 'LineChartContainerHeight'.toUpperCase() })
                let lineChartContainerHeightValue = lineChartContainerHeight && lineChartContainerHeight.value;

                let pieChartContainerWidth = data.table0.find(f => { return f.name.toUpperCase() === 'PieChartContainerWidth'.toUpperCase() })
                let pieChartContainerWidthValue = pieChartContainerWidth && pieChartContainerWidth.value;

                let pieChartContainerHeight = data.table0.find(f => { return f.name.toUpperCase() === 'PieChartContainerHeight'.toUpperCase() })
                let pieChartContainerHeightValue = pieChartContainerHeight && pieChartContainerHeight.value;

                let stackedbarContainerWidth = data.table0.find(f => { return f.name.toUpperCase() === 'StackedbarContainerWidth'.toUpperCase() })
                let stackedbarContainerWidthValue = stackedbarContainerWidth && stackedbarContainerWidth.value;

                let stackedbarContainerHeight = data.table0.find(f => { return f.name.toUpperCase() === 'StackedbarContainerHeight'.toUpperCase() })
                let stackedbarContainerHeightValue = stackedbarContainerHeight && stackedbarContainerHeight.value;

                let gaugeChartContainerTop = data.table0.find(f => { return f.name.toUpperCase() === 'GaugeChartContainerTop'.toUpperCase() })
                let gaugeChartContainerTopValue = gaugeChartContainerTop && gaugeChartContainerTop.value;

                let gaugeChartContainerBottom = data.table0.find(f => { return f.name.toUpperCase() === 'GaugeChartContainerBottom'.toUpperCase() })
                let gaugeChartContainerBottomValue = gaugeChartContainerBottom && gaugeChartContainerBottom.value;

                let gaugeChartContainerLeft = data.table0.find(f => { return f.name.toUpperCase() === 'GaugeChartContainerLeft'.toUpperCase() })
                let gaugeChartContainerLeftValue = gaugeChartContainerLeft && gaugeChartContainerLeft.value;

                let gaugeChartContainerRight = data.table0.find(f => { return f.name.toUpperCase() === 'GaugeChartContainerRight'.toUpperCase() })
                let gaugeChartContainerRightValue = gaugeChartContainerRight && gaugeChartContainerRight.value;

                let chartsBorderColor = data.table0.find(f => { return f.name.toUpperCase() === 'ChartsBorderColor'.toUpperCase() })
                let chartsBorderColorValue = chartsBorderColor && chartsBorderColor.value;

                let temp = colourStringChartSeriesValue;

                let colourStringChartArray = [];
                if (temp) {
                    colourStringChartArray = temp && temp.split(",");
                }


                let mapBubbleColor = data.table0.find(f => { return f.name.toUpperCase() === 'MapBubbleColor'.toUpperCase() })

                let mapBubbleOpacity = data.table0.find(f => { return f.name.toUpperCase() === 'MapBubbleOpacity'.toUpperCase() })

                let mapBubbleColorValue = mapBubbleColor && mapBubbleColor.value;
                let mapBubbleOpacityValue = mapBubbleOpacity && mapBubbleOpacity.value;

                let chartContainerColslg = data.table0.find(f => { return f.name.toUpperCase() === 'ChartContainer-cols-lg'.toUpperCase() })
                let chartContainerColslgValue = chartContainerColslg && chartContainerColslg.value;

                let chartContainerColsmd = data.table0.find(f => { return f.name.toUpperCase() === 'ChartContainer-cols-md'.toUpperCase() })
                let chartContainerColsmdValue = chartContainerColsmd && chartContainerColsmd.value;

                let chartContainerColssm = data.table0.find(f => { return f.name.toUpperCase() === 'ChartContainer-cols-sm'.toUpperCase() })
                let chartContainerColssmValue = chartContainerColssm && chartContainerColssm.value;

                let chartContainerColsxs = data.table0.find(f => { return f.name.toUpperCase() === 'ChartContainer-cols-xs'.toUpperCase() })
                let chartContainerColsxsValue = chartContainerColsxs && chartContainerColsxs.value;

                let chartContainerColsxxs = data.table0.find(f => { return f.name.toUpperCase() === 'ChartContainer-cols-xxs'.toUpperCase() })
                let chartContainerColsxxsValue = chartContainerColsxxs && chartContainerColsxxs.value;

                let chartContainerRowHeight = data.table0.find(f => { return f.name.toUpperCase() === 'ChartContainer-rowHeight'.toUpperCase() })
                let chartContainerRowHeightValue = chartContainerRowHeight && chartContainerRowHeight.value;

                let tokenExpirationPollInInterval = data.table0.find(f => { return f.name.toUpperCase() === 'TokenExpirationPollInInterval'.toUpperCase() })
                let tokenExpirationPollInIntervalValue = tokenExpirationPollInInterval && tokenExpirationPollInInterval.value;

                var rootCSSConfig = document.querySelector(':root')

                if (colourChartTextValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourChartText-color', colourChartTextValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourChartText-color', '#cbcbcb');
                }

                if (colourBackGroundValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourBackground-color', colourBackGroundValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourBackground-color', '#333333');
                }

                if (colourCardLineValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourCardLine-color', colourCardLineValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourCardLine-color', '#cbcbcb');
                }

                if (colourCardTextTitleValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourCardTextTitle-color', colourCardTextTitleValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourCardTextTitle-color', '#cbcbcb');
                }

                if (colourChartLineValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourChartLine-color', colourChartLineValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourChartLine-color', '#cbcbcb');
                }


                if (colourKeyValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourKey-color', colourKeyValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourKey-color', '#e20000');
                }

                if (colourStringChartSeriesValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourStringChartSeries-color', colourStringChartSeriesValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-colourStringChartSeries-color', '#BAE08D,#8AB6EF,#F5CE85,#B0C1D4,#F78F8F');
                }

                let colors = colourStringChartSeriesValue?.toString()?.split(',')

                let logOutBtnColor = colors[colors.length - 1];

                rootCSSConfig.style.setProperty('--vision-dashboard-log-out-btn-color', logOutBtnColor);

                if (mapBubbleColorValue) {
                    rootCSSConfig.style.setProperty('--map-bubble-color', mapBubbleColorValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--map-bubble-color', '#BAE08D');
                }

                if (mapBubbleOpacityValue) {
                    rootCSSConfig.style.setProperty('--map-bubble-opacity', mapBubbleOpacityValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--map-bubble-opacity', 0.7);
                }



                if (barChartContainerWidthValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-barChartContainer-width', barChartContainerWidthValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-barChartContainer-width', '95%');
                }

                if (barChartContainerHeightValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-barChartContainer-height', barChartContainerHeightValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-barChartContainer-height', '85%');
                }


                if (doughnutChartContainerWidthValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-doughnutChartContainer-width', doughnutChartContainerWidthValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-doughnutChartContainer-width', '100%');
                }


                if (doughnutChartContainerHeightValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-doughnutChartContainer-height', doughnutChartContainerHeightValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-doughnutChartContainer-height', '85%');
                }


                if (funnelChartContainerWidthValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-funnelChartContainer-width', funnelChartContainerWidthValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-funnelChartContainer-width', '98%');
                }

                if (funnelChartContainerHeightValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-funnelChartContainer-height', funnelChartContainerHeightValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-funnelChartContainer-height', '90%');
                }

                if (gaugeChartContainerWidthValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-width', gaugeChartContainerWidthValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-width', '100%');
                }

                if (gaugeChartContainerHeightValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-height', gaugeChartContainerHeightValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-height', '90%');
                }

                if (lineChartContainerWidthValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-lineChartContainer-width', lineChartContainerWidthValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-lineChartContainer-width', '98%');
                }

                if (lineChartContainerHeightValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-lineChartContainer-height', lineChartContainerHeightValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-lineChartContainer-height', '90%');
                }

                if (pieChartContainerWidthValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-pieChartContainer-width', pieChartContainerWidthValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-pieChartContainer-width', '100%');
                }

                if (pieChartContainerHeightValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-pieChartContainer-height', pieChartContainerHeightValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-pieChartContainer-height', '85%');
                }

                if (stackedbarContainerWidthValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-stackedbarContainer-width', stackedbarContainerWidthValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-stackedbarContainer-width', '95%');
                }

                if (stackedbarContainerHeightValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-stackedbarContainer-height', stackedbarContainerHeightValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-stackedbarContainer-height', '85%');
                }

                if (gaugeChartContainerTopValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-top', gaugeChartContainerTopValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-top', 20);
                }

                if (gaugeChartContainerBottomValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-bottom', gaugeChartContainerBottomValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-bottom', 0);
                }

                if (gaugeChartContainerLeftValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-left', gaugeChartContainerLeftValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-left', 0);
                }


                if (gaugeChartContainerRightValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-right', gaugeChartContainerRightValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-gaugeChartContainer-right', 0);
                }

                if (chartContainerColslgValue) {
                    rootCSSConfig.style.setProperty('--ChartContainer-cols-lg', chartContainerColslgValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--ChartContainer-cols-lg', 60);
                }
                if (chartContainerColsmdValue) {
                    rootCSSConfig.style.setProperty('--ChartContainer-cols-md', chartContainerColsmdValue);

                }
                else {
                    rootCSSConfig.style.setProperty('--ChartContainer-cols-md', 10);
                }

                if (chartContainerColssmValue) {
                    rootCSSConfig.style.setProperty('--ChartContainer-cols-sm', chartContainerColssmValue);

                }
                else {
                    rootCSSConfig.style.setProperty('--ChartContainer-cols-sm', 6);
                }

                if (chartContainerColsxsValue) {
                    rootCSSConfig.style.setProperty('--ChartContainer-cols-xs', chartContainerColsxsValue);

                }
                else {
                    rootCSSConfig.style.setProperty('--ChartContainer-cols-xs', 4);
                }

                if (chartContainerColsxxsValue) {
                    rootCSSConfig.style.setProperty('--ChartContainer-cols-xxs', chartContainerColsxxsValue);

                }
                else {
                    rootCSSConfig.style.setProperty('--ChartContainer-cols-xxs', 2);
                }

                if (chartContainerRowHeightValue) {
                    rootCSSConfig.style.setProperty('--ChartContainer-rowHeight', chartContainerRowHeightValue);

                }
                else {
                    rootCSSConfig.style.setProperty('--ChartContainer-rowHeight', 5);
                }

                if (chartsBorderColorValue) {
                    rootCSSConfig.style.setProperty('--vision-dashboard-chartsContainerBorder-color', chartsBorderColorValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--vision-dashboard-chartsContainerBorder-color', '#c0c0c0');
                }

                if (tokenExpirationPollInIntervalValue) {
                    rootCSSConfig.style.setProperty('--Token-Expiration-Poll-In-Interval', tokenExpirationPollInIntervalValue);
                }
                else {
                    rootCSSConfig.style.setProperty('--Token-Expiration-Poll-In-Interval', '60');
                }

                let colorCode = {
                    visiondashboardcolourChartTextcolor: colourChartTextValue,
                    visiondashboardcolourBackgroundcolor: colourBackGroundValue,
                    visiondashboardcolourCardLinecolor: colourCardLineValue,
                    visiondashboardcolourCardTextTitlecolor: colourCardTextTitleValue,
                    visiondashboardcolourChartLinecolor: colourChartLineValue,
                    visiondashboardcolourKeycolor: colourKeyValue,
                    visiondashboardcolourStringChartSeries: colourStringChartArray
                }

                props.setStoreDashboardBrandColors(colorCode)

            }

        }

        getConstantsFn();

    }, [])

    const MaterialArtic = React.lazy(() => import('./MaterialArctic/MaterialArtic'));
    const MaterialNova = React.lazy(() => import('./MaterialNova/MaterialNova'));
    const MaterialPacificDark = React.lazy(() => import('./MaterialPacificDark/MaterialPacificDark'));
    const MaterialMain = React.lazy(() => import('./MaterialMain/MaterialMain'));
    const MaterialLimeDark = React.lazy(() => import('./MaterialLimeDark/MaterialLimeDark'));
    const MaterialBurntTeal = React.lazy(() => import('./MaterialBurntTeal/MaterialBurntTeal'));

    const { appTheme, children } = props;

    return (
        <React.Fragment>
            <React.Suspense fallback={<></>}>
                {appTheme === 'Arctic' && <MaterialArtic />}
                {appTheme === 'Nova' && <MaterialNova />}
                {appTheme === 'PacificDark' && <MaterialPacificDark />}
                {appTheme === 'Main' && <MaterialMain />}
                {appTheme === 'LimeDark' && <MaterialLimeDark />}
                {appTheme === 'BurntTeal' && <MaterialBurntTeal />}
            </React.Suspense>
            {children}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {


        setStoreDashboardBrandColors: (dashboardBrandColors) =>
            dispatch(setStoreDashboardBrandColorsAction(dashboardBrandColors)),
        setStoreSiteConstants: (constants) =>
            dispatch(setSiteConstantsAction(constants))
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSelector);

