import { SET_BAR_CHART_CONTAINER_SIZE } from './barChartContainerSizeType'

const initialState = {
    barChartContainerSize: {}
}

const setBarChartContainerSizeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BAR_CHART_CONTAINER_SIZE: return {
            ...state,
            barChartContainerSize: action.payload

        }

        default: return state;
    }
}

export default setBarChartContainerSizeReducer;