import { SET_ACTIVE_USER } from './activeUserType'

const initialState = {}

const setActiveUserReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ACTIVE_USER: return{
            ...state,
            activeUser: action.payload
        }

        default: return state;
    }
}

export default setActiveUserReducer;