import { SET_BAR_CHART_YEAR } from './barChartYearType'

const initialState = {
    barChartYear: ''
}

const setBarChartYearReducer = (state = initialState, action)=> {
    switch(action.type){
        case SET_BAR_CHART_YEAR: return{
            ...state,
            barChartYear: action.payload
        }

        default: return state;
    }
}

export default setBarChartYearReducer;