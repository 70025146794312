import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from './serviceWorker';

import AppKendoUi from './AppKendoUi'

import ThemeSelector from './app/components/Themes/ThemeSelector'

import './fontawesome_6_0_0/css/all.css'

import {
  BrowserRouter as Router

} from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";

import DashBoardErrorPage from './app/components/DashBoardErrorPage/DashBoardErrorPage';

import $ from 'jquery'

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import './app/components/Toast/Toast.css'

import { INTERNAL_SERVER_ERROR } from './app/components/SiteConstants/SiteConstants'

import setOAuthErrorDataAction from './redux/oAuthError/oAuthErrorDataAction'

toast.configure();

// - response
$.ajaxSetup({


  beforeSend: (xhr, settings) => {

    // xhr.abort();

    // return false;

    // debugger;

    // console.log('ajax beforeSend xhr = ', xhr)

    // console.log('ajax beforeSend settings = ', settings)




    // console.log('ajax beforeSend xhr = ', xhr)

    // console.log('ajax beforeSend settings = ', settings)





    // let online = siteOnline()

    // if (online === 'FALSE') {

    //   console.log('successfully aborted request, reason network offline');

    //   xhr.abort();
    //   return;
    // }

  },

  complete: (xhr, status) => {

    if (xhr?.status?.toString() === '200')
      store.dispatch(setOAuthErrorDataAction({}));


  },

  error: (xhr, status, error) => {

    let _valid_Error_Description_Array = ["refresh token expired", "id token expired", "refresh token revoked", "id token required", "access token required"]

    let statusCode = xhr && xhr.status;

    let statusText = xhr?.statusText;

    let responseJson = xhr && xhr.responseJSON;

    let responseText = xhr && xhr.responseText;

    let _error = responseJson?.error;
    let _error_description = responseJson?.error_description;

    if (statusCode?.toString() === '401'
      || statusText?.toString()?.toLowerCase === 'unauthorized') {
      console.log('xhr = ', xhr)
      return;
    }
    else if (statusCode?.toString() === '400'
      && statusText.toString()?.toLowerCase() === 'Bad Request'.toLowerCase()
      && (_error === 'invalid_grant' || _error === 'invalid_request')
      && _valid_Error_Description_Array.includes(_error_description) === true) {
      console.log('xhr = ', xhr)
      return;
    }
    else if (statusCode?.toString() === '400'
      && statusText.toString()?.toLowerCase() === 'Bad Request'.toLowerCase()
      && (_error === 'invalid_grant' || _error === 'invalid_request')
      && _valid_Error_Description_Array.includes(_error_description) === false) {

      //Redirect the user to error page
      let object = {
        status: statusCode?.toString(),
        error: _error,
        error_description: _error_description

      };

      store.dispatch(setOAuthErrorDataAction(object));

      return;

    }
    //Assuming only status 500 left now
    else {

      if (statusCode?.toString() === '500') {

        toast.error(INTERNAL_SERVER_ERROR, {
          theme: "colored",
        });

      }

    }

  }

})

// //Http interceptors for axios - request
// axios.interceptors.request.use(function (config) {


// }, function (error) {
//   // console.log('http interceptor request axios - error', error)
//   return error;
// })

// //-response
// axios.interceptors.response.use(function (config) {
//   return config;
// }, function (error) {



// })




ReactDOM.render(
  // <React.StrictMode>
  //   <Provider store={store}>
  //   <PersistGate loading={null} persistor={persistor}>
  //     <ThemeSelector
  //      appTheme = {"Nova"} 
  //     >
  //       <AppKendoUi />
  //     </ThemeSelector>
  //     </PersistGate>
  //   </Provider>
  // </React.StrictMode>,
  <React.StrictMode>

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router
          forceRefresh={true}
        >
          <ThemeSelector
            appTheme={"Main"}
          >
            <ErrorBoundary
              FallbackComponent={DashBoardErrorPage}
              onReset={() => {


              }}
            >
              <AppKendoUi />
            </ErrorBoundary>
          </ThemeSelector>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
