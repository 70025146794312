import { SET_CHART_LEVEL } from './chartLevelType'

const initialState = []

const setChartLevelReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_CHART_LEVEL: return{
            ...state,
            chartLevel: action.payload
        }

        default: return state;
    }
}

export default setChartLevelReducer;