import { SET_GAUGE_CHART_DRILL_DOWN_LABEL_TYPE } from './gaugeChartDrillDownLabelType'

const initialState = []

const setGaugeChartDrillDownLabelReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_GAUGE_CHART_DRILL_DOWN_LABEL_TYPE: return{
            ...state,
            gaugeChartDrillDownLabel: action.payload
        }

        default: return state;
    }
}

export default setGaugeChartDrillDownLabelReducer;