import { SET_MENU_OPTIONS } from './menuOptionsType'

const initialState = []

const setMenuOptionsReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_MENU_OPTIONS: return{
            ...state,
            menuOptions: action.payload
        }

        default: return state;
    }
}

export default setMenuOptionsReducer;