import {SET_Dashboard_BRAND_COLORS} from './dashboardBrandColorsType'

const setStoreDashboardBrandColorsAction = (dashboardBrandColors)=> {
    return {
        type: SET_Dashboard_BRAND_COLORS,
        payload: dashboardBrandColors
    }

}

export default setStoreDashboardBrandColorsAction;