import { SET_EDITED_WIDGET } from './editedWidgetType'

const initialState = {}

const setEditedWidgetReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_EDITED_WIDGET: return{
            ...state,
            editedWidget: action.payload
        }

        default: return state;
    }
}

export default setEditedWidgetReducer;