import { SET_PIE_CHART_DRILL_DOWN_LABEL_TYPE } from './pieChartDrillDownLabelType'

const initialState = []

const setPieChartDrillDownLabelReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_PIE_CHART_DRILL_DOWN_LABEL_TYPE: return{
            ...state,
            pieChartDrillDownLabel: action.payload
        }

        default: return state;
    }
}

export default setPieChartDrillDownLabelReducer;