import { SET_DUMMY_PAGE_LAYOUT } from './dummyPageLayOutType'

const initialState = []

const setDummyPageLayOutReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_DUMMY_PAGE_LAYOUT: return{
            ...state,
            dummyPageLayOut: action.payload
        }

        default: return state;
    }
}

export default setDummyPageLayOutReducer;