import { SET_HUB_TOKEN_DATA } from './hubTokenDataType'

const initialState = {}

const setHubTokenDataReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_HUB_TOKEN_DATA: return{
            ...state,
            hubTokenData: action.payload
        }

        default: return state;
    }
}

export default setHubTokenDataReducer;