import { SET_VISION_CLIENTS } from './visionClientsType'

const initialState = []

const setVisionClientsReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_VISION_CLIENTS: return{
            ...state,
            visionClients: action.payload
        }

        default: return state;
    }
}

export default setVisionClientsReducer;