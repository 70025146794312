import React from 'react'
import { Loader } from "@progress/kendo-react-indicators";
import LoaderAppCSS from './LoaderApp.module.css'

function LoaderApp() {
    return (
        <React.Fragment>
            <div className={LoaderAppCSS.loadercontainer}>
                <Loader size="large" type={"converging-spinner"} />
            </div>
        </React.Fragment>
    )
}

export default LoaderApp;
