import { SET_STACKED_BAR_CHART_DRILL_DOWN_LABEL_TYPE } from './stackedBarChartDrillDownLabelType'

const initialState = []

const setStackedBarChartDrillDownLabelReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_STACKED_BAR_CHART_DRILL_DOWN_LABEL_TYPE: return{
            ...state,
            stackedBarChartDrillDownLabel: action.payload
        }

        default: return state;
    }
}

export default setStackedBarChartDrillDownLabelReducer;