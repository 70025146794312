import { SET_CSRF_TOKEN } from './csrfTokenType'

const initialState = {}

const setCSRFTokenReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_CSRF_TOKEN: return{
            ...state,
            csrfToken: action.payload
        }

        default: return state;
    }
}

export default setCSRFTokenReducer;