import axios from "axios";

import $ from 'jquery'

import *as qs from 'qs'

//import { hubUrl } from './../app/components/appconfig'

import { TKN_150 } from './../app/components/customconstants'

import { UNQUE_USER_IDENTIFIER_COOKIE } from './../app/components/SiteConstants/SiteConstants'

import { store } from "./../redux/store";

import { getHubUrl } from './getChartData'


export const userToken = async () => {

    const TKN150 = TKN_150;

    let token = '';
    let nameEQ = encodeURIComponent(TKN150) + "=";
    let ca = document.cookie.split(";");

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            token = decodeURIComponent(c.substring(nameEQ.length, c.length));
    }

    return token;

}

export const userUniqueIdentifier = async () => {

    let uniqueidentifier = '';

    let nameEQ = encodeURIComponent(UNQUE_USER_IDENTIFIER_COOKIE) + "=";
    let ca = document.cookie.split(";");

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            uniqueidentifier = decodeURIComponent(c.substring(nameEQ.length, c.length));
    }

    return uniqueidentifier;

}

export const hubToken = async (clientId, clientSecret,
    userName, password) => {

    let hubUrl = await getHubUrl();

    let token = await oauthAccessTokenFromStore();

    let data = new Object();
    data.grant_type = "password";
    data.username = userName;
    data.password = password;
    data.client_id = clientId;
    data.client_secret = clientSecret;
    data.scopes = "openid offline_access";

    return new Promise((resolve, reject) => {

        $.ajax({
            url: hubUrl + "connect/token",
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(data),
            headers: { "Authorization": `Bearer ${token}` },

        })
            .then((res, textStatus, jqXHR) => {
                let data = res && res.data;
                resolve(data);

            })
            .fail((jqXHR, textStatus, errorThrown) => {

                let errorObject = {
                    jqXHR: jqXHR,
                    textStatus: textStatus,
                    errorThrown: errorThrown,
                };
                reject(errorObject);

            });

    })


};

export const hubTokenByExchangeOfToken = async (clientId, clientSecret,
    apptoken) => {

    let hubUrl = await getHubUrl();

    let token = await oauthAccessTokenFromStore();

    let data = new Object();
    data.grant_type = "urn:ietf:params:oauth:grant-type:token-exchange";
    data.client_id = clientId;
    data.client_secret = clientSecret;
    data.apptoken = apptoken;
    data.scopes = "openid offline_access";


    return new Promise((resolve, reject) => {

        $.ajax({
            url: hubUrl + "connect/token",
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(data),
            headers: { "Authorization": `Bearer ${token}` },

        })
            .then((res, textStatus, jqXHR) => {
                let data = res && res.data;
                resolve(data);

            })
            .fail((jqXHR, textStatus, errorThrown) => {

                let errorObject = {
                    jqXHR: jqXHR,
                    textStatus: textStatus,
                    errorThrown: errorThrown,
                };
                reject(errorObject);

            });

    })

    // let data = new Object();
    // data.grant_type = "urn:ietf:params:oauth:grant-type:token-exchange";
    // data.client_id = clientId;
    // data.client_secret = clientSecret;
    // data.apptoken = apptoken;
    // data.scopes = "openid offline_access";

    // let dataString = qs.stringify(data)

    // return new Promise((resolve, reject) => {
    //     axios({
    //         method: "post",
    //         url: hubUrl + "connect/token",
    //         headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //         data: dataString,
    //     })
    //         .then((res) => {

    //             resolve(res)

    //         })
    //         .catch((err) => {
    //             reject(err);
    //         });
    // });

};

export const oauthAccessTokenFromStore = async () => {

    let state = store.getState();

    let oAuthTokenData = state?.oAuthTokenData?.oAuthTokenData;

    let token = oAuthTokenData?.tkn;

    return token;

}

export const csrfTokenFromStore = async () => {

    let state = store.getState();

    let token = state?.csrfToken?.csrfToken

    return token;

}

export const userLoginTokenFromStore = async () => {

    let state = store.getState();

    let token = state?.userLoginToken?.userLoginToken

    return token;

}