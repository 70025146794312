import { appBaseApiUrl } from "./../app/components/appconfig";

import $ from 'jquery'

import { oAuthAccessToken, oAuthRefreshToken } from './oAuthTokenService'

import { refreshIdToken } from './idTokenService'

import { store } from "./../redux/store";

import { oauthAccessTokenFromStore } from './tokenService'

import setOAuthTokenDataAction from './../redux/oAuthToken/oAuthTokenDataAction'

import {
    GRANT_TYPE_ACCESS_TOKEN,
    GRANT_TYPE_REFRESH_TOKEN,
    GRANT_TYPE_ID_TOKEN_EXCHANGE,
    GRANT_TYPE_ID_REFRESH_TOKEN
} from './../app/components/SiteConstants/SiteConstants'

import { mutex } from './lockService'

import { wait } from './commonService'

// class Lock {
//     constructor() {
//         this.isLocked = false;
//         this.queue = [];
//     }

//     async acquire() {
//         return new Promise((resolve) => {
//             if (!this.isLocked) {
//                 this.isLocked = true;
//                 resolve();
//             } else {
//                 this.queue.push(resolve);
//             }
//         });
//     }

//     release() {
//         if (this.queue.length > 0) {
//             const resolve = this.queue.shift();
//             resolve();
//         } else {
//             this.isLocked = false;
//         }
//     }
// }


export const commonGet = async (path, data) => {

    try {

        let _result = await mutex.runExclusive(async () => {

            let _path = path;

            let _data = data;

            let grant_type = GRANT_TYPE_ACCESS_TOKEN;

            let _final = {};

            let errorObject = {}

            let isError = false;

            let status = '';

            let statusText = '';

            let responseJSON = '';

            let error = '';

            let error_description = ''

            let ajaxConfig = await ajaxConfigGet(_path, _data)

            try {
                _final = await $.ajax(ajaxConfig)
            }
            catch (err) {

                console.log('error invoking method using access token = ', err)

                isError = true;

                status = err?.status;

                statusText = err?.statusText;

                responseJSON = err?.responseJSON;

                if (responseJSON
                    && JSON.stringify(responseJSON)?.length > 0) {
                    error = responseJSON?.error;
                    error_description = responseJSON?.error_description;
                }

                if (status?.toString() === '401') {

                    let _tokenError = false;

                    let token = await oauthAccessTokenFromStore();

                    if (!token
                        || token?.length <= 0) {

                        grant_type = GRANT_TYPE_ID_TOKEN_EXCHANGE

                        let _res = await oAuthAccessToken()
                            .catch((jqXHR, textStatus, errorThrown) => {

                                _tokenError = true;

                                let _reCreateTokenErrorObj = {
                                    jqXHR: jqXHR?.jqXHR,
                                    textStatus: textStatus,
                                    errorThrown: errorThrown,
                                }

                                console.log('id token grant - error = ', _reCreateTokenErrorObj)

                                status = jqXHR?.jqXHR?.status;

                                statusText = jqXHR?.jqXHR?.statusText;

                                responseJSON = jqXHR?.jqXHR?.responseJSON

                                if (responseJSON
                                    && JSON.stringify(responseJSON)?.length > 0) {
                                    error = responseJSON?.error;
                                    error_description = responseJSON?.error_description;
                                }

                            })

                        if (!_tokenError) {

                            await replaceOAuthAccessTokenInStore(_res);
                        }

                    }
                    else {

                        grant_type = GRANT_TYPE_REFRESH_TOKEN;

                        let _res = await oAuthRefreshToken(token)
                            .catch((jqXHR, textStatus, errorThrown) => {

                                _tokenError = true;

                                let _reCreateTokenErrorObj = {
                                    jqXHR: jqXHR?.jqXHR,
                                    textStatus: textStatus,
                                    errorThrown: errorThrown,
                                }

                                console.log('refresh token grant - error = ', _reCreateTokenErrorObj)

                                status = jqXHR?.jqXHR?.status;

                                statusText = jqXHR?.jqXHR?.statusText;

                                responseJSON = jqXHR?.jqXHR?.responseJSON

                                if (responseJSON
                                    && JSON.stringify(responseJSON)?.length > 0) {
                                    error = responseJSON?.error;
                                    error_description = responseJSON?.error_description;
                                }

                            })

                        if (!_tokenError) {

                            await replaceOAuthAccessTokenInStore(_res);
                        }
                    }

                }

                if (status?.toString() === '400'
                    && (error === 'invalid_grant' || error === 'invalid_request')
                    && (error_description === 'refresh token expired' || error_description === 'refresh token revoked')
                ) {

                    let _tokenError = false;

                    grant_type = GRANT_TYPE_ID_TOKEN_EXCHANGE

                    let _res = await oAuthAccessToken()
                        .catch((jqXHR, textStatus, errorThrown) => {

                            _tokenError = true;

                            let _reCreateTokenErrorObj = {
                                jqXHR: jqXHR?.jqXHR,
                                textStatus: textStatus,
                                errorThrown: errorThrown,
                            }

                            console.log('id token grant - error = ', _reCreateTokenErrorObj)

                            status = jqXHR?.jqXHR?.status;

                            statusText = jqXHR?.jqXHR?.statusText;

                            responseJSON = jqXHR?.jqXHR?.responseJSON

                            if (responseJSON
                                && JSON.stringify(responseJSON)?.length > 0) {
                                error = responseJSON?.error;
                                error_description = responseJSON?.error_description;
                            }

                        })

                    if (!_tokenError) {

                        await replaceOAuthAccessTokenInStore(_res);
                    }


                }

                if (status?.toString() === '400'
                    && (error === 'invalid_grant' || error === 'invalid_request')
                    && error_description === 'id token expired'
                ) {

                    //Check oauth refresh token expired, after that refresh the id token
                    //Valid oauth refresh token should recreate access token again
                    let _tokenError1 = false;

                    let token = await oauthAccessTokenFromStore();

                    grant_type = GRANT_TYPE_REFRESH_TOKEN;

                    let _res = await oAuthRefreshToken(token)
                        .catch(async (jqXHR, textStatus, errorThrown) => {

                            _tokenError1 = true;

                            let _reCreateTokenErrorObj = {
                                jqXHR: jqXHR?.jqXHR,
                                textStatus: textStatus,
                                errorThrown: errorThrown,
                            }

                            console.log('refresh token grant - error = ', _reCreateTokenErrorObj)

                            status = jqXHR?.jqXHR?.status;

                            statusText = jqXHR?.jqXHR?.statusText;

                            responseJSON = jqXHR?.jqXHR?.responseJSON

                            if (responseJSON
                                && JSON.stringify(responseJSON)?.length > 0) {
                                error = responseJSON?.error;
                                error_description = responseJSON?.error_description;
                            }

                            //Now good to refresh the id token 
                            //Refresh it
                            if (status?.toString() === '400'
                                && (error === 'invalid_grant' || error === 'invalid_request')
                                && (error_description === 'access token required' || error_description === 'refresh token expired' || error_description === 'refresh token revoked')
                            ) {

                                let _tokenError2 = false;

                                grant_type = GRANT_TYPE_ID_REFRESH_TOKEN;

                                let _res = await refreshIdToken()
                                    .catch((jqXHR, textStatus, errorThrown) => {

                                        _tokenError2 = true;

                                        let _reCreateTokenErrorObj = {
                                            jqXHR: jqXHR?.jqXHR,
                                            textStatus: textStatus,
                                            errorThrown: errorThrown,
                                        }

                                        console.log('id token refresh grant - error = ', _reCreateTokenErrorObj)

                                        status = jqXHR?.jqXHR?.status;

                                        statusText = jqXHR?.jqXHR?.statusText;

                                        responseJSON = jqXHR?.jqXHR?.responseJSON

                                        if (responseJSON
                                            && JSON.stringify(responseJSON)?.length > 0) {
                                            error = responseJSON?.error;
                                            error_description = responseJSON?.error_description;
                                        }

                                    })

                                if (!_tokenError2) {

                                    let _tokenError3 = false;

                                    grant_type = GRANT_TYPE_ID_TOKEN_EXCHANGE

                                    let _res = await oAuthAccessToken()
                                        .catch((jqXHR, textStatus, errorThrown) => {

                                            _tokenError3 = true;

                                            let _reCreateTokenErrorObj = {
                                                jqXHR: jqXHR?.jqXHR,
                                                textStatus: textStatus,
                                                errorThrown: errorThrown,
                                            }

                                            console.log('id token grant after refreshing id token - error = ', _reCreateTokenErrorObj)

                                            status = jqXHR?.jqXHR?.status;

                                            statusText = jqXHR?.jqXHR?.statusText;

                                            responseJSON = jqXHR?.jqXHR?.responseJSON

                                            if (responseJSON
                                                && JSON.stringify(responseJSON)?.length > 0) {
                                                error = responseJSON?.error;
                                                error_description = responseJSON?.error_description;
                                            }

                                        })

                                    if (!_tokenError3) {
                                        await replaceOAuthAccessTokenInStore(_res);
                                    }

                                }

                            }

                        })

                    if (!_tokenError1) {

                        await replaceOAuthAccessTokenInStore(_res);
                    }

                }

            }

            if (isError) {

                try {

                    let ajaxConfig = await ajaxConfigGet(_path, _data)

                    _final = await $.ajax(ajaxConfig)

                    isError = false;
                }
                catch (err) {

                    isError = true;

                    errorObject = err;

                }
            }

            if (isError) {
                return Promise.reject(errorObject);
            }
            else {
                let _d = _final;

                return Promise.resolve(_d);
            }
        });

        return Promise.resolve(_result);
    }
    catch (err) {

        return Promise.reject(err);

    }

};

export const commonPost = async (path, data,
    dataType, contentType) => {

    try {

        let _result = await mutex.runExclusive(async () => {

            let _path = path;

            let _data = data;

            let _dataType = dataType;

            let _contentType = contentType;

            let grant_type = GRANT_TYPE_ACCESS_TOKEN;

            let _final = {};

            let errorObject = {}

            let isError = false;

            let status = '';

            let statusText = '';

            let responseJSON = '';

            let error = '';

            let error_description = ''

            let ajaxConfig = await await ajaxConfigPost(_path,
                _data, _dataType, _contentType)
            try {
                _final = await $.ajax(ajaxConfig)
            }
            catch (err) {

                console.log('error invoking method using access token = ', err)

                isError = true;

                status = err?.status;

                statusText = err?.statusText;

                responseJSON = err?.responseJSON;

                if (responseJSON
                    && JSON.stringify(responseJSON)?.length > 0) {
                    error = responseJSON?.error;
                    error_description = responseJSON?.error_description;
                }

                if (status?.toString() === '401') {

                    let _tokenError = false;

                    let token = await oauthAccessTokenFromStore();

                    if (!token
                        || token?.length <= 0) {

                        grant_type = GRANT_TYPE_ID_TOKEN_EXCHANGE

                        let _res = await oAuthAccessToken()
                            .catch((jqXHR, textStatus, errorThrown) => {

                                _tokenError = true;

                                let _reCreateTokenErrorObj = {
                                    jqXHR: jqXHR?.jqXHR,
                                    textStatus: textStatus,
                                    errorThrown: errorThrown,
                                }

                                console.log('id token grant - error = ', _reCreateTokenErrorObj)

                                status = jqXHR?.jqXHR?.status;

                                statusText = jqXHR?.jqXHR?.statusText;

                                responseJSON = jqXHR?.jqXHR?.responseJSON

                                if (responseJSON
                                    && JSON.stringify(responseJSON)?.length > 0) {
                                    error = responseJSON?.error;
                                    error_description = responseJSON?.error_description;
                                }

                            })

                        if (!_tokenError) {

                            await replaceOAuthAccessTokenInStore(_res);
                        }

                    }
                    else {

                        grant_type = GRANT_TYPE_REFRESH_TOKEN;

                        let _res = await oAuthRefreshToken(token)
                            .catch((jqXHR, textStatus, errorThrown) => {

                                _tokenError = true;

                                let _reCreateTokenErrorObj = {
                                    jqXHR: jqXHR?.jqXHR,
                                    textStatus: textStatus,
                                    errorThrown: errorThrown,
                                }

                                console.log('refresh token grant - error = ', _reCreateTokenErrorObj)

                                status = jqXHR?.jqXHR?.status;

                                statusText = jqXHR?.jqXHR?.statusText;

                                responseJSON = jqXHR?.jqXHR?.responseJSON

                                if (responseJSON
                                    && JSON.stringify(responseJSON)?.length > 0) {
                                    error = responseJSON?.error;
                                    error_description = responseJSON?.error_description;
                                }

                            })

                        if (!_tokenError) {

                            await replaceOAuthAccessTokenInStore(_res);
                        }
                    }

                }

                if (status?.toString() === '400'
                    && (error === 'invalid_grant' || error === 'invalid_request')
                    && (error_description === 'refresh token expired' || error_description === 'refresh token revoked')
                ) {

                    let _tokenError = false;

                    grant_type = GRANT_TYPE_ID_TOKEN_EXCHANGE

                    let _res = await oAuthAccessToken()
                        .catch((jqXHR, textStatus, errorThrown) => {

                            _tokenError = true;

                            let _reCreateTokenErrorObj = {
                                jqXHR: jqXHR?.jqXHR,
                                textStatus: textStatus,
                                errorThrown: errorThrown,
                            }

                            console.log('id token grant - error = ', _reCreateTokenErrorObj)

                            status = jqXHR?.jqXHR?.status;

                            statusText = jqXHR?.jqXHR?.statusText;

                            responseJSON = jqXHR?.jqXHR?.responseJSON

                            if (responseJSON
                                && JSON.stringify(responseJSON)?.length > 0) {
                                error = responseJSON?.error;
                                error_description = responseJSON?.error_description;
                            }

                        })

                    if (!_tokenError) {

                        await replaceOAuthAccessTokenInStore(_res);
                    }


                }

                if (status?.toString() === '400'
                    && (error === 'invalid_grant' || error === 'invalid_request')
                    && error_description === 'id token expired'
                ) {

                    //Check oauth refresh token expired, after that refresh the id token
                    //Valid oauth refresh token should recreate access token again
                    let _tokenError1 = false;

                    let token = await oauthAccessTokenFromStore();

                    grant_type = GRANT_TYPE_REFRESH_TOKEN;

                    let _res = await oAuthRefreshToken(token)
                        .catch(async (jqXHR, textStatus, errorThrown) => {

                            _tokenError1 = true;

                            let _reCreateTokenErrorObj = {
                                jqXHR: jqXHR?.jqXHR,
                                textStatus: textStatus,
                                errorThrown: errorThrown,
                            }

                            console.log('refresh token grant - error = ', _reCreateTokenErrorObj)

                            status = jqXHR?.jqXHR?.status;

                            statusText = jqXHR?.jqXHR?.statusText;

                            responseJSON = jqXHR?.jqXHR?.responseJSON

                            if (responseJSON
                                && JSON.stringify(responseJSON)?.length > 0) {
                                error = responseJSON?.error;
                                error_description = responseJSON?.error_description;
                            }

                            //Now good to refresh the id token 
                            //Refresh it
                            if (status?.toString() === '400'
                                && (error === 'invalid_grant' || error === 'invalid_request')
                                && (error_description === 'access token required' || error_description === 'refresh token expired' || error_description === 'refresh token revoked')
                            ) {

                                let _tokenError2 = false;

                                grant_type = GRANT_TYPE_ID_REFRESH_TOKEN;

                                let _res = await refreshIdToken()
                                    .catch((jqXHR, textStatus, errorThrown) => {

                                        _tokenError2 = true;

                                        let _reCreateTokenErrorObj = {
                                            jqXHR: jqXHR?.jqXHR,
                                            textStatus: textStatus,
                                            errorThrown: errorThrown,
                                        }

                                        console.log('id token refresh grant - error = ', _reCreateTokenErrorObj)

                                        status = jqXHR?.jqXHR?.status;

                                        statusText = jqXHR?.jqXHR?.statusText;

                                        responseJSON = jqXHR?.jqXHR?.responseJSON

                                        if (responseJSON
                                            && JSON.stringify(responseJSON)?.length > 0) {
                                            error = responseJSON?.error;
                                            error_description = responseJSON?.error_description;
                                        }

                                    })

                                if (!_tokenError2) {

                                    let _tokenError3 = false;

                                    grant_type = GRANT_TYPE_ID_TOKEN_EXCHANGE

                                    let _res = await oAuthAccessToken()
                                        .catch((jqXHR, textStatus, errorThrown) => {

                                            _tokenError3 = true;

                                            let _reCreateTokenErrorObj = {
                                                jqXHR: jqXHR?.jqXHR,
                                                textStatus: textStatus,
                                                errorThrown: errorThrown,
                                            }

                                            console.log('id token grant after refreshing id token - error = ', _reCreateTokenErrorObj)

                                            status = jqXHR?.jqXHR?.status;

                                            statusText = jqXHR?.jqXHR?.statusText;

                                            responseJSON = jqXHR?.jqXHR?.responseJSON

                                            if (responseJSON
                                                && JSON.stringify(responseJSON)?.length > 0) {
                                                error = responseJSON?.error;
                                                error_description = responseJSON?.error_description;
                                            }

                                        })

                                    if (!_tokenError3) {
                                        await replaceOAuthAccessTokenInStore(_res);
                                    }

                                }

                            }

                        })

                    if (!_tokenError1) {

                        await replaceOAuthAccessTokenInStore(_res);
                    }

                }

            }

            if (isError) {

                try {

                    let ajaxConfig = await await ajaxConfigPost(_path,
                        _data, _dataType, _contentType)

                    _final = await $.ajax(ajaxConfig)

                    isError = false;
                }
                catch (err) {

                    isError = true;

                    errorObject = err;

                }
            }

            if (isError) {
                return Promise.reject(errorObject);
            }
            else {
                let _d = _final;

                return Promise.resolve(_d);
            }
        });

        return Promise.resolve(_result);
    }
    catch (err) {

        return Promise.reject(err);

    }

};

const replaceOAuthAccessTokenInStore = async (_res) => {

    let access_token = _res.access_token;

    let _obj = new Object();

    _obj.tkn = access_token;

    store.dispatch(setOAuthTokenDataAction(_obj))

    return await wait(1000);

}

const ajaxConfigGet = async (_path, _data) => {

    let token = await oauthAccessTokenFromStore();

    //data - Object array
    let dataObject = new Object();

    if (_data?.length > 0) {

        Object.entries(_data).forEach(([key, value]) => {

            let _value = value;

            Object.keys(_value).forEach((_k) => {

                dataObject[_k] = _value[_k];

            })

        });
    }

    let ajaxConfig = new Object();
    ajaxConfig.url = appBaseApiUrl + _path;
    ajaxConfig.type = "GET";
    ajaxConfig.headers = { "Authorization": `Bearer ${token}` };
    ajaxConfig.data = dataObject;

    return Promise.resolve(ajaxConfig);

}

const ajaxConfigPost = async (_path, _data,
    _dataType, _contentType) => {

    let token = await oauthAccessTokenFromStore();

    //data - Object array
    let dataObject = new Object();

    if (_data?.length > 0) {

        Object.entries(_data).forEach(([key, value]) => {

            let _value = value;

            Object.keys(_value).forEach((_k) => {

                dataObject[_k] = _value[_k];

            })

        });
    }

    let ajaxConfig = new Object();
    ajaxConfig.url = appBaseApiUrl + _path;
    ajaxConfig.type = "POST";
    ajaxConfig.headers = { "Authorization": `Bearer ${token}` };

    if (_data.length > 0) {
        ajaxConfig.dataType = _dataType;
        ajaxConfig.contentType = _contentType;
        ajaxConfig.data = JSON.stringify(dataObject);
    }

    return Promise.resolve(ajaxConfig);

}

