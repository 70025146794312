import React, { useState, useEffect } from 'react'

import { connect } from "react-redux";

import './DashBoardErrorPage.css'

import { Button } from "@progress/kendo-react-buttons";

import { appBaseApiUrl } from "./../appconfig";

import $ from 'jquery'

import { useSearchParams } from 'react-router-dom'

import { clearStore } from './../../../services/clearStoreService'

import { RootUrl } from './../../../services/generalService'

import { userLogOut } from './../../../services/logOutService'

import { Loader } from "@progress/kendo-react-indicators";

function DashBoardErrorPage(props) {

    const [searchParams, setSearchParams] = useSearchParams();

    const [showLoaderReload, setShowLoaderReload] = useState(false)

    const [showLoaderLogOut, setShowLoaderLogOut] = useState(false)

    let error = props.error

    console.log('Error returned from error boundary = ', error?.toString())

    const reloadClick = async () => {

        setShowLoaderReload(true)

        await clearStore();

        await new Promise(resolve => setTimeout(resolve, 500))

        localStorage.removeItem('persist:root')

        await new Promise(resolve => setTimeout(resolve, 500))

        window.location.assign('/')

        // let rootUrl = await RootUrl();

        // window.location.href = rootUrl;

        //history.pushState(null, null, rootUrl);
    }

    const LogOutClick = async () => {

        setShowLoaderLogOut(true)

        await userLogOut(searchParams);
    }


    return (

        <React.Fragment>
            <div className="dashboard-error-page-container">
                <div className="error-text">
                    <p>
                        {"Error occured while processing the request"}
                    </p>
                    <p>
                        {"Try reloading the site once and if you face same issue again log out from the site and re-login again"}
                    </p>
                </div>
                <div className="details-wrapper">
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {error?.toString()}
                    </details>
                </div>
                <div className="btn-container">
                    <div className="reload-btn-wrapper">
                        <Button type={"button"}
                            tabIndex="-1"
                            onClick={(e) => {
                                reloadClick(e);

                            }}
                            className="dashboard-brand-primary-control error-page reload-btn"
                        >
                            {
                                showLoaderReload ? (
                                    <Loader
                                        size="medium"
                                        type={"pulsing"}
                                        themeColor={"inherit"}
                                    />
                                ) : "Reload"
                            }
                        </Button>
                    </div>
                    <div className="sign-out-btn-wrapper">
                        <Button type={"button"}
                            tabIndex="-1"
                            onClick={(e) => {

                                LogOutClick(e)

                            }}
                            className="dashboard-brand-primary-control error-page sign-out-btn"
                        >
                            {
                                showLoaderLogOut ? (
                                    <Loader
                                        size="medium"
                                        type={"pulsing"}
                                        themeColor={"inherit"}
                                    />
                                ) : "Log Out"
                            }
                        </Button>
                    </div>
                </div>
            </div>

        </React.Fragment>

    )

}

const mapStateToProps = (state) => {
    return {

        authorizationDataFromStore: state.authorizationData,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardErrorPage);