
import { appBaseApiUrl } from "./../app/components/appconfig"

import $ from 'jquery'

export const IdTokenCalims = async () => {

    return new Promise((resolve, reject) => {

        $.ajax({
            type: 'GET',
            url: appBaseApiUrl + "IDToken/IdentityToken",
        })
            .then((data) => {
                resolve(data);
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                let errorObject = {
                    jqXHR: jqXHR,
                    textStatus: textStatus,
                    errorThrown: errorThrown,
                };
                reject(errorObject);
            });


    })

}


export const refreshIdToken = async () => {

    return new Promise((resolve, reject) => {

        $.ajax({
            type: 'POST',
            url: appBaseApiUrl + "IDToken/RefreshTokenAsync",
        })
            .then((data) => {
                resolve(data);
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                let errorObject = {
                    jqXHR: jqXHR,
                    textStatus: textStatus,
                    errorThrown: errorThrown,
                };
                reject(errorObject);
            });


    })

}