import { SET_OAUTH_ERROR_DATA } from './oAuthErrorDataType'

const initialState = {}

const setOAuthErrorDataReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_OAUTH_ERROR_DATA: return{
            ...state,
            oAuthErrorData: action.payload
        }

        default: return state;
    }
}

export default setOAuthErrorDataReducer;