import { SET_PAGE_FILTER_AVAILABLE } from './pageFilterAvailableType'

const initialState = ''

const setPageFilterAvailableReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_PAGE_FILTER_AVAILABLE: return{
            ...state,
            pageFilterAvailable: action.payload
        }

        default: return state;
    }
}

export default setPageFilterAvailableReducer;