import { SET_DONUT_CHART_YEAR } from './donutChartYearType'

const initialState = {
    donutChartYear: ''
}

const setDonutChartYearReducer = (state = initialState, action)=> {
    switch(action.type){
        case SET_DONUT_CHART_YEAR: return{
            ...state,
            donutChartYear: action.payload
        }

        default: return state;
    }
}

export default setDonutChartYearReducer;