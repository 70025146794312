import { SET_PAGE_FILTERS } from './pageFiltersType'

const initialState = {}

const setPageFiltersReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_PAGE_FILTERS: return{
            ...state,
            pageFilters: action.payload
        }

        default: return state;
    }
}

export default setPageFiltersReducer;