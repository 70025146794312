import { SET_PIE_CHART_YEAR } from './pieChartYearType'

const initialState = {
    pieChartYear: ''
}

const setPieChartYearReducer = (state = initialState, action)=> {
    switch(action.type){
        case SET_PIE_CHART_YEAR: return{
            ...state,
            pieChartYear: action.payload
        }

        default: return state;
    }
}

export default setPieChartYearReducer;