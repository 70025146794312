import { SET_SELECTED_MENU_OPTION } from './selectedMenuOptionType'

const initialState = {}

const setSelectedMenuOptionReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_SELECTED_MENU_OPTION: return{
            ...state,
            selectedMenuOption: action.payload
        }

        default: return state;
    }
}

export default setSelectedMenuOptionReducer;