import { SET_USER_LOGIN_TOKEN } from './userLoginTokenType'

const setUserLoginTokenAction = (token)=> {

    debugger;

    return {
        type: SET_USER_LOGIN_TOKEN,
        payload: token
    }
}

export default setUserLoginTokenAction;