import React, { useState, useEffect, useRef } from "react";
import "./App.css";

import { Typography } from "@progress/kendo-react-common";

import { connect } from "react-redux";

import setLayOutAction from './redux/layOut/layOutAction'

// import { toast } from "react-toastify";

// import "react-toastify/dist/ReactToastify.css";

import { Routes, Route, Link, useLocation } from "react-router-dom";

import {
    LoadableOAuthToken,
    LoadableHome,
    LoadableError,
    LoadableUnAuthorizedUser,
    LoadableMaintaincePage,
    LoadableOAuthError
} from './LazyRoutes'

import CacheBuster from 'react-cache-buster';

import { version } from '../package.json';

import { useNavigate } from "react-router-dom";

import { useSearchParams } from 'react-router-dom'

// toast.configure();

function AppKendoUi(props) {

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {

        let _data = props.oAuthErrorDataFromStore.oAuthErrorData;

        if (_data
            && JSON.stringify(_data) !== '{}') {

            let _status = _data?.status;

            let _error = _data?.error;

            let _error_description = _data.error_description;

            if (_status?.toString().length > 0
                || _error?.toString().length > 0
                || _error_description?.toString()?.length > 0) {

                let userType = 2;

                let userName = searchParams.get('u');

                if (userName
                    && userName?.length > 0) {
                    userName = encodeURIComponent(userName)
                }

                let password = searchParams.get('p')

                if (password
                    && password?.length > 0) {
                    password = encodeURIComponent(password)
                }

                let menuOption = searchParams.get('menuoption');
                let pageFilter = searchParams.get('pagefilter');

                if (!userName
                    || userName?.length <= 0) {
                    userName = ''
                }

                if (!password
                    || password?.length <= 0) {
                    password = ''
                }

                if (!menuOption
                    || menuOption?.length <= 0) {
                    menuOption = ''
                }

                if (!pageFilter
                    || pageFilter?.length <= 0) {
                    pageFilter = ''
                }

                let search = ''

                if (userName?.length > 0
                    || password?.length > 0) {

                    userType = 1;

                    search += `?u=${userName}&p=${password}`
                }

                if (menuOption?.length > 0
                    || pageFilter?.length > 0) {

                    if (Number(userType) > 1)
                        search += `?menuoption=${menuOption}&pagefilter=${pageFilter}`
                    else if (Number(userType) === 1)
                        search += `&menuoption=${menuOption}&pagefilter=${pageFilter}`
                }

                navigate({
                    pathname: "/oautherror",
                    search: search
                })

            }

        }

    }, [props.oAuthErrorDataFromStore.oAuthErrorData])


    return (

        <React.Fragment>


            <div id="route-contents-container" className="route-contents-container">
                <CacheBuster
                    currentVersion={version}
                    isEnabled={true} //If false, the library is disabled.
                    // isVerboseMode={false} //If true, the library writes verbose logs to console.
                    loadingComponent={<div style={{
                        width: '100%',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold'
                    }}>{"Loading....."}</div>} //If not pass, nothing appears at the time of new version check.
                    // metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
                    //reloadOnDowngrade={true}
                    // onCacheClear={(refreshCacheAndReload)=> {

                    //     console.log('cache clear clicked')

                    // }

                    //}
                    onCacheClear={(refreshCacheAndReload) => {

                        console.log('----version change detected---')

                        refreshCacheAndReload()


                    }}
                >

                    <Routes>
                        <Route
                            path={`/`}
                            strict
                            exact
                            element={<LoadableOAuthToken />}
                        />
                        <Route
                            path={`/home`}
                            strict
                            exact
                            element={<LoadableHome />}
                        />
                        <Route
                            strict
                            exact
                            path="/unauthorized"
                            element={<LoadableUnAuthorizedUser />}
                        />
                        <Route
                            path="*"
                            element={<LoadableError />}
                        />
                        <Route
                            path="/maintenance"
                            element={<LoadableMaintaincePage />}
                        />
                        <Route
                            path="/oautherror"
                            element={<LoadableOAuthError />}
                        />
                    </Routes>
                </CacheBuster>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {

    return {
        layOutDataFromStore: state.layOut,
        oAuthErrorDataFromStore: state.oAuthErrorData

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setStorelayOut: (layout) => dispatch(setLayOutAction(layout)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppKendoUi);
